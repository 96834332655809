import React,{useEffect} from "react";
import image from './Header/tym.png';
import {Header} from  '../Elements/Header/header';
import {Footer} from '../Elements/Footer/footer';

const RefundBanner = 'assets/images/bg/Refund-Banner.jpg';

const RefundPolicy = ({accountData}) => {
  
  useEffect(()=>{
    window.scrollTo({
     top: 0,
     behavior: 'smooth'
    })
  },[])
  
  return (
    <React.Fragment>
      <Header accountData={accountData}/>
      <div style={{padding:"30px"}}>
      <h3 className="m_title text-center">Refund Policy</h3>
      <div className="edu-breadcrumb-area breadcrumb-style-1 ptb--90 ptb_sm--40 bg-image" 
        style={{backgroundImage: `url(${RefundBanner})`}}>
          </div>
      <div style={{height:"100vh"}}>
        We follow a strict No Refund policy. For the test attender after payment
        completion. So the Candidate please be aware of choosing the plan before
        paying the amount. In case of the Payment Cancelled or Transaction
        Decline and amount debited from your Acccount, the testyourmetric will
        refund the amount within 7 working days. It is important that you send
        the refund request from the email address linked to your Testyourmetirc
        account in order to authorize it. If possible, please include the
        payment ID so we can complete the process as quickly as possible. 
        Refunds cannot be processed to third-party accounts. The name on your
        Testyourmetirc should match with the name of the bank account holder.
        There is no cancellation once the payment made.
      </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default RefundPolicy;
import React, { useEffect, useRef, useState } from "react";
import certificate from "./certificate.jpg";
import { jsPDF } from "jspdf";
import "./certificate.css";
import { placeholder, placeholderValues } from "./placeholder";

function AppHook2() {
  const [text, setText] = useState({});
  const [textList, setTextList] = useState([]);

  const [formType, setFormType] = useState("");

  console.log(text);

  const handleChange = (e) => {
    setText({ ...text, [e.target.name]: e.target.value });
    console.log(e.target.name);
    console.log(e.target.value);
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setTextList([...textList, text]);
    console.log(text);
  };

   const pdfDownload = (e) => {
    e.preventDefault();
    let doc = new jsPDF("landscape", "px", "A4");
    doc.html(
      document.getElementById("certify"),
      {
        callback: () => {
          doc.save("test.pdf");
        },
      }
    );
  };

  const canvas = useRef(null);
  const [image, setImage] = useState(certificate);

  useEffect(() => {
    const ctx = canvas.current.getContext("2d");
    let img = new Image();
    img.src = image;
    img.onload = function () {
      ctx.drawImage(img, 0, 0, 650, 425);
      ctx.font = "20px Yesteryear";
      ctx.fillText("ISSUED DATE", 380, 370, 200);
      ctx.fillText("", 350, 210, 200, 40);
    };
  }, []);
  useEffect(() => {
    const ctx = canvas.current.getContext("2d");
    console.log("textList UseEffect======>", textList);
    let img = new Image();
    img.src = image;
    img.onload = function () {
      ctx.drawImage(img, 0, 0, 650, 425);
      textList.forEach((row) => {
        ctx.font = row.Fontstyle;
        ctx.fillStyle = row.FillStyle;
        ctx.fillText(row.Text, row.Width, row.Height, 200);
      });
    };
  }, [textList]);

  const columns = Object.keys(text);
  console.log(text);
  console.log(columns);
  const handleRowDelete = (rowIndex) => {
    setTextList(textList.filter((value, index) => index !== rowIndex));
  };
  return (
    <>
    <div id="certify">
      <div  >
        <canvas id="canvasImg" ref={canvas} width={640} height={425} />
      </div>
      <button onClick={pdfDownload}>pdfDownload</button>
      <br />

      <select
        className="select"
        defaultValue=""
        onChange={(e) => setFormType(e.target.value)}
      >
        <option value="" disabled>
          Select Type
        </option>
        <option value="dynamic">Dynamic</option>
        <option value="static">Static</option>
      </select>

      <div>
        <form onSubmit={formSubmit}>
          {
            /*dynamictoggle*/ formType === "dynamic" && (
              <>
                <br />
                <label htmlFor="">Select:</label>
                <select
                  className="selectField"
                  name="Text"
                  id="dropdown"
                  defaultValue={""}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled hidden></option>
                  <option value="name">name</option>
                  <option value="date ">date</option>
                  <option value="collegeName">collegeName</option>
                  <option value="course">course</option>
                </select>
              </>
            )
          }
          {
            /*statictoggle*/ formType === "static" && (
              <>
                <label for="text">Text:</label>
                <input
                  id="text"
                  name="Text"
                  value={text.Text || ""}
                  type="text"
                  onChange={handleChange}
                  required
                />
              </>
            )
          }
          <br />
          {formType !== "" && (
            <>
              <label for="height">Height:</label>
              <input
                id="height"
                name="Height"
                value={text.Height || ""}
                type="text"
                onChange={handleChange}
                required
              />
              <br />
              <label for="width">Width:</label>
              <input
                id="width"
                name="Width"
                value={text.Width || ""}
                type="text"
                onChange={handleChange}
                required
              />
              <br />
              <label for="fontstyle">Fontstyle:</label>
              <input
                id="style"
                name="Fontstyle"
                value={text.Fontstyle || ""}
                type="text"
                onChange={handleChange}
                required
              />
              <br />
              <label for="fontstyle">Fillstyle:</label>
              <input
                id="style"
                name="FillStyle"
                value={text.FillStyle || ""}
                type="text"
                onChange={handleChange}
                required
              />
              <br /> <br />
              <input type="submit" className="add" value="ADD" />
            </>
          )}
        </form>
      </div>
      {textList.length > 0 ? (
        <table>
          <thead>
            <tr>
              {columns.map((col) => (
                <th>{col}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {textList.map((row, rowIndex) => (
              <tr>
                {columns.map((col) => (
                  <td>{row[col]}</td>
                ))}
                <button>
                  {" "}
                  <td onClick={(e) => handleRowDelete(rowIndex)}>
                    delete
                  </td>{" "}
                </button>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        ""
      )}
      </div>
    </>
  );
}

export default AppHook2;

export const menuItems = [
  {
    title: "Home",
    href: "",
    target: "",
    className: "",
  },
  {
    title: "About Us",
    href: "/about-us",
    className: "",
    subMenuClass: "",
  },
  {
    title: "Test",
    href: "/test",
    className: "",
    subMenuClass: "",
    // submenu: [
    //   { title: "course1", href: "./service 1", className: "" },
    //   { title: "course2", href: "./service 2", className: "" },
    //   {
    //     title: "course3",
    //     href: "./detail 3",
    //     className: "",
    //     subMenuClass: "",
    //     submenu: [
    //       { title: "course1", href: "./detail 3a", className: "" },
    //       { title: "course2", href: "./detail 3b", className: "" },
    //     ],
    //   },
    // ],

    // icon: { type: 'fa fa-', className: 'address-book' },
  },
  {
    title: "Testimonial",
    href:"",
    className:""
  },
  {
    title: 'Demo',
    href: '',
    className:''
  },
  {
    title: 'Contact us',
    href: '/contact-us',
    className: ''
  },
  {
    title: 'Admin',
    href: '/adminPage/user-upload',
    className:''
  },
  // {
  //   title: "Blog",
  //   href: "./service page",
  //   className: "",
  //   subMenuClass: "",
  //   submenu: [
  //     { title: "blog1", href: "./service 1", className: "" },
  //     { title: "blog2", href: "./service 2", className: "" },
  //     {
  //       title: "blog3",
  //       href: "./detail 3",
  //       className: "",
  //       subMenuClass: "",
  //       submenu: [
  //         { title: "blog1", href: "./detail 3a", className: "" },
  //         { title: "blog2", href: "./detail 3b", className: "" },
  //       ],
  //     },
  //   ],
  // },
];

export const ProfileDropdownItems = [
  {
      // title: "profile",
      // className:"classProfile",
      id: "profile",
      icon: { type: 'fa fa-', className:'user fa-2x' },
      
      subMenuClass:"",
      submenu: [
          
      {
      title: "My profile",
      id: "profileItem1",
      className: "",  
      href:'/my-profile'
      },
  {
      title: "Logout",
      id:"profileItem3",
      className:"",
    href: '/'
  }]
}
  

]

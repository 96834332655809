import update from 'immutability-helper';

const loggedIn = JSON.parse(localStorage.getItem("accountData"));

export const addToken = (accessToken,user,userTypes,userData) => {
    return { type: "ADD_USER_INFO", payload: { accessToken,user,userTypes,userData} };
};

export const removeToken = () =>{
    return {type: "REMOVE_USER_INFO"}
}

export const addTestSession = (testSessionObject) => {
    
    return { type: "ADD_TEST_SESSION",payload:testSessionObject }
}

export const updateUserInfo = (valueObj)=>{
    return { type: "UPDATE_USER_INFO", payload: valueObj }
}

export const loggedReducer =(state= loggedIn ? loggedIn : 
    {
    user: "",
    accessToken:"",
    userTypes:"",
    testSession:{
        testId:"",
        testEndTime:"",
        sectionsWithStatus:[]
    }
    },action)=>{
        switch(action.type){
            case "ADD_USER_INFO":
                var info= {
                            user: action.payload.user,
                            accessToken: action.payload.accessToken,
                            userTypes: action.payload.userTypes,
                        ...action.payload.userData};
                            state= {...state,...info}
                localStorage.setItem("accountData",JSON.stringify(state));
                return state;

            case "REMOVE_USER_INFO":
                localStorage.removeItem("accountData");
                return {};

            case "ADD_TEST_SESSION":
                var newState =Object.assign({},state);
                newState = update(newState, { testSession: { $set: action.payload } });
                localStorage.setItem('accountData',JSON.stringify(newState));
                return newState;

            case "UPDATE_USER_INFO":
                var newState = Object.assign({},state);
                newState ={...newState,...action.payload};
                localStorage.setItem('accountData',JSON.stringify(newState));
                return newState;

            default:
                return state;
        }
}


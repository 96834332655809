export  const quizConst = {
    
 // question page

 catagoryTitle: "Quantitative | Logical | Aptitude", 


 //dashboard


 //api call
 
 FAILURE : "failure",
 SUCCESS: "success",

    
// localstorage keys
LOCAL_QUIZ_TIME:"quizTime"





} 
import React, { useEffect, useState } from "react";
import showPwdImg from "./show-password.svg";
import hidePwdImg from "./hide-password.svg";
import { Link,useParams,useNavigate } from "react-router-dom";
import fetchData from "../serverCall/fetchData";
import { addToken } from "../redux/loginData";
import {connect} from "react-redux";
import { Header } from "../Elements/Header/header";
import { Footer} from '../Elements/Footer/footer';
import { isEmailValid,isEmpty } from "../utils/util"

function Login({addTokenToRedux,accountData}) {
   
 const navigate =useNavigate();
   useEffect(()=>{
     window.scrollTo({
      top: 0,
      behavior: 'smooth'
     })
   },[])

  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [error, setError] = useState(false);
  const [mail, setMail] = useState("");
  const [password, setPassWord] = useState("");
  const [responseMessage, setResponseMessage] = useState('');
  const [signupResponse,setSignupResponse]=useState('')
  const [loginError, setLoginError] = useState(false);
  const [registerError, setRegisterError] = useState(false);

  // register page
  const [formField, setFormField] = useState({});
  const [form, setForm] = useState([]);

  const handleChange = (e) => {
    let value = e.target.name !== "terms" ? e.target.value : e.target.checked;
    setFormField({ ...formField, [e.target.name]: value });
  };

  async function Login(e) {
    e.preventDefault();

    if(!isEmailValid(mail) || !password) {
      setLoginError(true);
      return ;
    }
    
    var Login = await fetchData({
      requestingPage: "Login",
      method: "post",
      url: "auth/login",
      data: { mail, password }
    });
    if (Login && Login.msg === "success") {
      setLoginError(false);
      setResponseMessage(Login.desc);
      addTokenToRedux(Login.asset.token, Login.asset.username,Login.asset.userTypes,Login.asset.userData);
      setTimeout(() => { navigate("/test") }, 1500);
    }
    else if (Login.msg === "failure") {
    setResponseMessage(Login.desc + ": " + Login.detail);
    } else { setResponseMessage(Login); }
  }

  async function signUp(e){
    e.preventDefault();
    const fields = ["name", "college", "email", "password", "confirmpwd", "degree",
                   "specialization", "mobile", "gender", "dob"]

    const termsCheckbox = document.getElementsByName("terms")[0];

    var isFormValid = fields.some(field=> {
           return  formField[field] &&  formField[field].length >0
    });
    if(!isFormValid || formField["password"] !== formField["confirmpwd"] ) {
      setRegisterError(true);
      return;
    }
    if(termsCheckbox.checked) {
      setRegisterError(false)
    }
    else {
      setRegisterError(true);
      return;
    }

    var SignUp = await fetchData({
        requestingPage: "SignUp",
        method: "post",
        url: "auth/signup",
        data: formField
    });
    if(SignUp && SignUp.msg==="success"){
        setSignupResponse(SignUp.desc);
        addTokenToRedux(SignUp.asset.token,SignUp.asset.username,SignUp.asset.userTypes,SignUp.asset.userData);
         setTimeout(()=>{navigate("/"); window.location.reload();},2000);
    }
    else if(SignUp.msg==="failure"){
        setSignupResponse(SignUp.desc+" "+SignUp.detail);
    } 
    else
    {
      setResponseMessage(SignUp);
    }
  }


  return (
    <>
<Header accountData={accountData} />
      <div className="edu-breadcrumb-area ptb--60 ptb_md--40 ptb_sm--40 breadcrumb-style-1 bg-image" style={{height:"150px"}}>
        <div className="container eduvibe-animated-shape">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb-inner text-start">
                <div className="page-title" style={{marginTop: "0px"}}>
                  <h3 className="title">Login & Register</h3>
                </div>
              </div>
            </div>
          </div>

          <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
            <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
              <div className="shape-image shape-image-1">
                <img
                  src="assets/images/shapes/shape-11-07.png"
                  alt="Shape Thumb"
                />
              </div>
              <div className="shape-image shape-image-2">
                <img
                  src="assets/images/shapes/shape-01-02.png"
                  alt="Shape Thumb"
                />
              </div>
              <div className="shape-image shape-image-3">
                <img
                  src="assets/images/shapes/shape-03.png"
                  alt="Shape Thumb"
                />
              </div>
              <div className="shape-image shape-image-4">
                <img
                  src="assets/images/shapes/shape-13-12.png"
                  alt="Shape Thumb"
                />
              </div>
              <div className="shape-image shape-image-5">
                <img
                  src="assets/images/shapes/shape-36.png"
                  alt="Shape Thumb"
                />
              </div>
              <div className="shape-image shape-image-6">
                <img
                  src="assets/images/shapes/shape-05-07.png"
                  alt="Shape Thumb"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="login-register-page-wrapper edu-section-gap bg-color-white" style={{padding: '30px 0'}}>
        <div className="container checkout-page-style">
          <div className="row g-5">
            <div className="col-lg-6">
              <div className="login-form-box">
                <h3 className="mb-30">Login</h3>
                <form className="login-form" onSubmit={Login}>
                  <div className="input-box mb--30">
                    <input type="text" placeholder="Email"  value={mail} onChange={(e)=>setMail(e.target.value)}/>
                    { loginError && !isEmailValid(mail) && <span className="formError"> Please enter a valid email address</span>}                  </div>
                  <div className="showHideIcon">
                  <div className="input-box mb--30" style={{position:"relative"}}>
                    <input
                      name="pwd"
                      placeholder="Enter Password"
                      type={isRevealPwd ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassWord(e.target.value)}
                    />
                    <img
                      title={isRevealPwd ? "Hide password" : "Show password"}
                      src={isRevealPwd ? hidePwdImg : showPwdImg}
                      onClick={() => setIsRevealPwd((prevState) => !prevState)}
                    />
                      { loginError && !isEmpty(password) && <span className="formError"> Password should not be empty</span> }
                  </div>
                  </div>
                  <div className="comment-form-consent input-box mb--30">
                    <input id="checkbox-1" type="checkbox" />
                    {/* <label for="checkbox-1">Remember Me</label> */}
                  </div>
                  <button className="rn-btn edu-btn w-100 mb--30" type="submit">
                    <span>Login</span>
                  </button>
                  <div className="formError">{responseMessage}</div>
                  <div className="input-box">
                    <a href="#/forgot-password" className="lost-password">
                      Lost your password?
                      </a>
                  </div>
                </form>
              </div>

            </div>
            <div className="col-lg-6">
              <div className="login-form-box">
                <h3 className="mb-30">Register</h3>
                <form className="register-form" onSubmit={signUp}>
                  <div className="input-box mb--30">
                    <input type="text" name="username" onChange={handleChange} placeholder="Full Name" />
                    { registerError && !formField["username"] && <span className="formError"> Please enter a name</span> }
                  </div>
                  <div className="input-box mb--30">
                    <input type="email" name="mail"  onChange={handleChange} placeholder="Email" />
                    { registerError && !formField["mail"] && !isEmailValid(formField["mail"]) && <span className="formError"> Please enter a valid email address</span> }
                  </div>
                  <div className="input-box mb--30">
                    <input type="password" name="password"  onChange={handleChange} placeholder="Password" />
                    { registerError && !formField["password"] && <span className="formError"> Please enter a password</span> }
                  </div>
                  <div className="input-box mb--30">
                    <input type="password" name="confirmpwd"  onChange={handleChange} placeholder="Confirm Password" />
                    { registerError && !formField["confirmpwd"] && <span className="formError"> Please enter a confirm password</span> }
                  </div>
                  <div className="input-box mb--30">
                  <input type="text" name="degree" onChange={handleChange} placeholder="Degree" />
                    { registerError && !formField["degree"] && <span className="formError"> Please enter a degree</span> }
                  </div>
                  <div className="input-box mb--30">
                    <input type="text" name="specialization"  onChange={handleChange} placeholder="Specialization" />
                    { registerError && !formField["specialization"] && <span className="formError"> Please enter a Specialization</span> }
                  </div>
                  <div className="input-box mb--30">
                    <input type="text" name="college"  onChange={handleChange} placeholder="College Name" />
                    { registerError && !formField["college"] && <span className="formError"> Please enter a college Name</span> }
                  </div>
                  <div className="input-box mb--30">
                    <input type="text" name="dob"  onChange={handleChange} onFocus={(e)=>e.currentTarget.type='date'}
                    onBlur={(e)=>e.currentTarget.type='text'}
                    placeholder="Date of Birth" />
                    { registerError && !formField["dob"] && <span className="formError"> Select the Date of birth</span> }
                  </div>
                  <div className="input-box mb--30">
                    <input
                      type="text"
                      placeholder="Mobile"
                      maxLength="10"
                      name="mobile"  onChange={handleChange}
                    />
                     { registerError && !formField["mobile"] && <span className="formError"> Please enter a mobile number</span> }
                  </div>
                  <div className="input-box mb--30">
                    <select defaultValue="" name="gender"  onChange={handleChange} >
                      <option value="" disabled>Gender</option>
                      <option name="male"> Male</option>
                      <option name="female">Female</option>
                      <option name="Rather Not to Say">
                        Rather Not to Say
                      </option>
                    </select>
                    { registerError && !formField["gender"] && <span className="formError"> Select the gender</span> }
                  </div>
                 
                  <button className="rn-btn edu-btn w-100 mb--30" type="submit">
                    <span>Register</span>
                  </button>
                
                  <div className="input-box">
                    <input id="checkbox-2" name="terms" onChange={handleChange} type="checkbox" />
                    <label htmlFor="checkbox-2">
                      I read & agree the <a className="help-text" href='#/terms-and-conditions' target="_blank">terms and conditions</a>.
                    </label>
                    { registerError && !formField["terms"] && <span className="terms formError"> Please accept the terms and conditions</span> }
                    <div className="formError">{signupResponse}</div>
                 </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="rn-progress-parent">
        <svg
          className="rn-back-circle svg-inner"
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
      </div>
      <Footer />
    </>
  );
}

const mapDisPatchToProps=(dispatch)=>{
  return {
      addTokenToRedux: (accessToken,username,userTypes,userData)=>dispatch(addToken(accessToken,username,userTypes,userData))
  }
}

export default connect(null,mapDisPatchToProps)(Login);
import React from "react";
import {NavLink, Route, Routes} from "react-router-dom";
import ExcelClass from "./excelClass";
import TilePage from "./tilePage";
import "./adminPage.css";
import {Header} from "../../Elements/Header/header";
import {Footer} from "../../Elements/Footer/footer";
import {TestPlan} from "./admintestplans";
import EditUserType from "./userTypePermission";
import UserAudit from "../Audit/audit";
import AppHook2 from "./certificate/certificate";
import Feedback from "./fbpage";
import {FeedBackList} from "./feedBackList/feedBackList";
import UserUpload from "./userUpload";

const AdminPage = ({accountData}) => {
  function openNav() {
    document.getElementsByClassName("navigationContainer")[0].style.width =
      "250px";

  }
  function closeNav() {
    document.getElementsByClassName("navigationContainer")[0].style.width = "0";
  }

  return (
    <>
      <Header accountData={accountData} />
      <div className="navigationContainer" style={{textAlign: "left"}}>
        <div className="closebtn" onClick={e => closeNav()}>
          X
        </div>
        <div className="forEdit">
          <NavLink
            className="link"
            to="/adminPage/user-upload"
            onClick={e => closeNav()}
          >
            Create User
          </NavLink>
        </div>
        <div className="forEdit">
          <NavLink
            className="link"
            to="/adminPage/create-question"
            onClick={e => closeNav()}
          >
            Create Question
          </NavLink>
        </div>
        <div className="forEdit">
          <NavLink
            className="link"
            to="/adminPage/edit-questions"
            onClick={() => closeNav()}
          >
            Edit Set
          </NavLink>
        </div>
        <div className="forEdit">
          <NavLink
            className="link"
            to="/adminPage/create-test-plan"
            onClick={e => closeNav()}
          >
            Create Test Plan
          </NavLink>
        </div>
        <div className="forEdit">
          <NavLink
            className="link"
            to="/adminPage/edit-user-plan"
            onClick={e => closeNav()}
          >
            Edit User Type
          </NavLink>
        </div>
        <div className="forEdit">
          <NavLink
            className="link"
            to="/adminPage/user-audit"
            onClick={e => closeNav()}
          >
            User Audit
          </NavLink>
        </div>
        <div className="forEdit">
          <NavLink
            className="link"
            to="/adminPage/certificate"
            onClick={e => closeNav()}
          >
            Certificate
          </NavLink>
        </div>
        <div className="forEdit">
          <NavLink
            className="link"
            to="/adminPage/feedback"
            onClick={e => closeNav()}
          >
            Create FeedBack
          </NavLink>
        </div>
        <div className="forEdit">
          <NavLink
            className="link"
            to="/adminPage/feedBackList"
            onClick={e => closeNav()}
          >
            Feed Back List
          </NavLink>
        </div>
      </div>
      <div className="openNav" onClick={e => openNav()}>
        &#9776;
      </div>
      <Routes>
        <Route
          index
          path="/user-upload"
          element={
            <UserUpload  accountData={accountData}/>
          }
        />
        <Route
          path="/create-question"
          element={<ExcelClass accountData={accountData} />}
        />
        <Route
          path="/edit-questions"
          element={<TilePage accountData={accountData} />}
        />
        <Route
          path="/create-test-plan"
          element={<TestPlan accountData={accountData} />}
        />
        <Route
          path="/edit-user-plan"
          element={<EditUserType accountData={accountData} />}
        />
        <Route
          path="/user-audit"
          element={<UserAudit accountData={accountData} />}
        />
        <Route
          path="/certificate"
          element={<AppHook2 accountData={accountData} />}
        />
        <Route
          path="/feedback"
          element={<Feedback accountData={accountData} />}
        />
        <Route
          path="/feedbacklist"
          element={<FeedBackList accountData={accountData} />}
        />
      </Routes>
      {false &&<Footer />}
    </>
  );
};
export default AdminPage;

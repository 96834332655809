import {useNavigate} from 'react-router-dom'
import Carousel, { CarouselItem } from "./carousel.js";
import singleBanner from './Banner-3.jpg';
import React from 'react';


export const carouselImages = [
  {
    url: singleBanner
  }
];
function Banner() {
  const navigate= useNavigate();
  return (
    <div className="" style={{marginTop:"0px"}}>
  <Carousel>
      {carouselImages.map((images,ind) => {
        return (
          <CarouselItem key={ind}>
            <div style={{marginTop:"0px",width:"100%"}}>
              <img
                // height={1200}
                // className="image"
                alt="Banner"
                 style={{ marginTop:"0px",width:"100%" }}
                src={images.url}
              />{" "}
               <div
                className="read-more-btn mt--40"
                // data-sal-delay="150"
                // data-sal="slide-up"
                // data-sal-duration="800"
                style={{opacity:"0"}}
              >
                <a
                  className="edu-btn getstart"
                  //style={{ position: "absolute", top: 550, left: 140 }}
                  onClick={()=>navigate("/test")}
                >
                  Choose Your Plan
                  <i className="icon-arrow-right-line-right"></i>
                </a>
              </div>
            </div>
          </CarouselItem>)})}
      </Carousel>
    </div>
  );
}

export default Banner;

import React, { Fragment, useEffect, useRef, useState } from 'react'
import './userUpload.css'
import * as XLSX from "xlsx";
import fetchData from '../../serverCall/fetchData';

const UserUpload = ({accountData}) => {
    const [userDetailForm, setUserDetailForm] = useState({user_types:["Free"]});
    const [availablePlans,setAvailablePlans] = useState([]);
    const [responseMessage,setResponseMessage] = useState("");
    const [formType,setFormType] = useState(false);
    const [excelData,setExcelData] = useState(null);

    const columns=[{name:"Name",key:"name"},{name:"Mail",key:"mail"},{name:"Date of Birth",key:"dob"},
                    {name:"Gender",key:"gender"},{name:"Degree",key:"degree"},
                    {name:"Specialization",key:"specialization"},{name:"College",key:"college"},
                    {name:"Mobile",key:"mobile"},{name:"User Plan Types",key:"user_types"}];

    const getPlans = async () => {
        var resultData = await fetchData({
          url: "adminPanel/list-plans",
          method: "get",
          headers: { token: accountData?.accessToken, username: accountData?.user },
        });
        if (resultData.msg === "success") {
          if (resultData.asset.length > 0) {
            resultData.asset.forEach((plan) => (plan.checkedStatus = false));
            setAvailablePlans(resultData.asset);
          } else {
            setAvailablePlans([]);
          }
        } else {
          setAvailablePlans([]);
        }
      };

      useEffect(()=>{
        getPlans();
      },[]);
      
      useEffect(()=>{
        if(responseMessage){
            setTimeout(()=>{
                setResponseMessage("")
            },3000)
        }
      },[responseMessage]) 

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserDetailForm((prev) => ({ ...prev, [name]: value }));
    }

    const handleUserTypeChange = (e) => {
        const { checked, value } = e.target;
        let userDetailFormCopy = { ...userDetailForm };

        if (checked) {
            userDetailFormCopy.user_types = [...userDetailFormCopy.user_types, value];
        } else {
            userDetailFormCopy.user_types = userDetailFormCopy.user_types.filter((planName) => planName !== value)
        }

        setUserDetailForm(userDetailFormCopy);
    }

    const handleSubmit=async(e)=>{
        e.preventDefault();
        var resultData = await fetchData({
            url: "adminPanel/create-user",
            method: "post",
            data: userDetailForm,
            headers: { token: accountData?.accessToken, username: accountData?.user },
          });

          if(resultData.msg === "success"){
            setUserDetailForm({user_types:["Free"]});
            setResponseMessage("User Created Successfully");
          }else{
            setResponseMessage("Something Went Wrong Please Try Later : " + resultData.detail);
          }

    }

    const handleFileView=(file)=>{
       if(file !== null){
            const workbook = XLSX.read(file,{type:"Buffer",cellDates:true});
            const worksheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[worksheetName];
            let data = XLSX.utils.sheet_to_json(worksheet,{defval:''});
            data = data.map((({user_types,...rest})=>({...rest,user_types:user_types.split(",")})))
            let errorCheck = data.some((obj)=>columns.some((col)=>obj[col.key] === ''));
            if(errorCheck){
                setResponseMessage("All Fields are Mandatory, Some fields are missing in the file");
            }
            let invalidPlans = data.some((obj)=>obj.user_types.some((ust)=>!availablePlans.some((plan)=>plan.planName === ust)));
            if(invalidPlans){
                setResponseMessage("Some User Plan Types in sheet or not active or not available");
            }
            if(!errorCheck && !invalidPlans){
                setExcelData(data);
            }else{
                setExcelData(null);
            }

        }
    }

    const handleFile=(e)=>{
        let usersFile = e.target.files[0];
        if(usersFile && usersFile.type === "text/csv"){
                let reader = new FileReader();
                reader.readAsArrayBuffer(usersFile);
                reader.onload = (e)=>{
                    handleFileView(e.target.result);
                }
        }else{
            setResponseMessage("Please Upload Only CSV Files")
        }

    }
    const fileInputRef = useRef(null);

    useEffect(()=>{
        if(!formType){
            setExcelData(null);
          if(fileInputRef.current)
            fileInputRef.current.value = "";
        }
    },[formType])

    const submitSheet=async()=>{
        let submitResult = await fetchData({
            url: "adminPanel/create-user-sheet",
            method: "post",
            data: {data: excelData},
            headers: { token: accountData?.accessToken, username: accountData?.user },            
        })

        if(submitResult.msg === "success"){
            setResponseMessage("Successfully Uploaded Data");
            setExcelData(null);
            if(fileInputRef.current)
            fileInputRef.current.value = "";
        }else{
            setResponseMessage("Something Went Wrong : "+ submitResult.data.detail.detail)
        }
    }
    const switchComponent=()=>{

        switch(formType){
            case false:
                return (
                    <form className='form-container' autoComplete='off' onSubmit={handleSubmit}>

                        <div className='form-input'>
                            <label>Name*</label>
                            <input type="text" name="name" value={userDetailForm.name || ""} required onChange={handleChange} />
                        </div>

                        <div className='form-input'>

                            <label>Mail*</label>
                            <input type="email" name="mail" value={userDetailForm.mail || ""} required onChange={handleChange} />
                        </div>

                        <div className='form-input'>

                            <label>Date of Birth*</label>
                            <input type="date" name="dob" value={userDetailForm.dob || ""} required onChange={handleChange} />
                        </div>
                        <div className='form-input'>

                            <label>Gender*</label>
                            <select value={userDetailForm.gender || ""} name="gender" required onChange={handleChange} >
                                <option value="" disabled>Gender</option>
                                <option name="male"> Male</option>
                                <option name="female">Female</option>
                                <option name="Rather Not to Say">
                                    Rather Not to Say
                                </option>
                            </select>
                        </div>

                        <div className='form-input'>

                            <label>Degree*</label>
                            <input type="text" name="degree" required value={userDetailForm.degree || ""} onChange={handleChange} />
                        </div>

                        <div className='form-input'>
                            <label>Specialization*</label>
                            <input type="text" name="specialization" required value={userDetailForm.specialization || ""} onChange={handleChange} />
                        </div>

                        <div className='form-input'>
                            <label>College*</label>
                            <input type="text" name="college" required value={userDetailForm.college || ""} onChange={handleChange} />
                        </div>

                        <div className='form-input'>
                            <label>Mobile* (exactly 10 digits)</label>
                            <input type="text" pattern="^\d{10}$" name="mobile" required value={userDetailForm.mobile || ""} onChange={handleChange} />
                        </div>

                        <div className='form-input'>
                            <label>User Type*</label>
                            <label id="Free" style={{ display: "block" }}>
                                <input type="checkbox" htmlFor="Free" name="plan-type" checked={true} value="Free" disabled style={{ display: "inline" }} />
                                Free (Free)
                            </label>
                            {availablePlans.map((plan, index) => (
                                <Fragment>
                                    <label id={plan.planId} style={{ display: "block" }}>
                                        <input type="checkbox" key={index} htmlFor={plan.planId} name="plan-name" style={{ display: "inline" }}
                                            checked={userDetailForm.user_types?.includes(plan.planName) || false} value={plan.planName}
                                            onChange={(e) => handleUserTypeChange(e)}
                                        />
                                        {plan.planDisplayName} ({plan.planName})
                                    </label>
                                </Fragment>
                            ))}
                        </div>
                        <input type="submit" value="Submit User Data" />
                    </form>);
            case true:
                return (<div className='form'>
                    <form className='form-group'>
                            <input type="file" accept='text/csv' className='form-control'
                            onChange={handleFile}
                            ref={fileInputRef}
                            required
                            style={{width:  !excelData ? "110px" : "30%" , height: "40px", display:"inline"}} 
                            />
                            {excelData && <button onClick={()=>{
                                setExcelData(null);
                                fileInputRef.current.value = "";
                            }}>Delete</button>}
                            <div>
                                {excelData && <button onClick={()=>{
                                    submitSheet();
                                }}>Upload</button>}
                            </div>
                            <div className='viewer'>
                                {excelData === null && <>{responseMessage}</>}
                                    <div className='tableContainer'>
                                        <table className='table'>
                                            <thead>
                                                <tr style={{padding:"20px"}}>
                                                    {columns.map(({name}, i) => (
                                                        <th key={i} className='columnData'>
                                                            {name}
                                                        </th>
                                                    ))}    
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {excelData && excelData.length > 0 ? (
                                                    excelData.map((row,i)=>{
                                                            return(
                                                                <tr key={i}>
                                                                {
                                                                    columns.map((col,j)=>(
                                                                        <td className='rowData' key={j}>
                                                                            {col.key==='dob' ? row[col.key].toLocaleDateString()  :row[col.key]}
                                                                        </td>
                                                                    ))
                                                                }
                                                                </tr>
                                                            )
                                                    })
                                                ) : null}
                                            </tbody>
                                        </table>
                                    </div>
                            </div>
                    </form>
                </div>);
        default:
            return ("No Form Type")
        }
    }
    return (
        <div className='container adminPage user-upload' style={{ marginLeft: '10em', height: '85vh' }}>
            <h6 style={{ marginLeft: '5em', color: '#525FE1' }}>All Fields are Mandatory*</h6>
            {responseMessage && <center>
                <span style={{ color: '#525FE1', fontSize: '18px', textAlign: "center", fontWeight: '700' }}>{responseMessage}</span>
            </center>
            }
            <label className="toggle" style={{ marginLeft: "3em", border: '2px solid gray',padding: "1em",borderRadius:"20px" }}>
                <span className={`toggle-label ${!formType && "active"}`}>Single User</span>
                <input className="toggle-checkbox" type="checkbox" checked={formType} onChange={(e) => setFormType(e.target.checked)} />
                <div className="toggle-switch"></div>
                <span className={`toggle-label ${formType && 'active'}`}>Upload Multiple Users in CSV Sheet</span>
            </label>
            {switchComponent()}
        </div>
    )

}

export default UserUpload

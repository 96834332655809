import React, { useEffect, useRef, useState } from "react";
import certificate from "../adminPanel/certificate/certificate.jpg";
import { jsPDF } from "jspdf";
import "../adminPanel/certificate/certificate.css";
import { Header } from '../../Elements/Header/header'
import { Footer } from '../../Elements/Footer/footer'
import './editUser.css'
import fetchData from '../../serverCall/fetchData'
import { connect } from "react-redux";
import {addToken} from '../../redux/loginData';

 function EditUser({accountData,addTokenToRedux}){
    const [planExpiry,setPlanExpiry] = useState([]);
    const [alert, setAlert] = useState();
    // const [textList, setTextList] = useState([]);
    const [userDetails, setUserDetails] = useState({});
    const [fieldError,setfieldError] = useState(false);
    const handleChange = (e) => {
      console.log(e.target.name,e.target.value,"ee")
      setUserDetails( {...userDetails,[e.target.name]:e.target.value});
    }
    const pdfDownload = (e) => {
        e.preventDefault();
        let doc = new jsPDF("landscape", "px", "A4");
        doc.html(
          document.getElementById("certify"),
          {
            callback: () => {
              doc.save("test.pdf");
            },
          }
        );
      };
    
      const canvas = useRef(null);
      const [image, setImage] = useState(certificate);
       const date=new Date();
       const getdate=date.getDate()+"/"+date.getMonth(+1)+"/"+date.getFullYear();

      useEffect(() => {
        getPlanExpiry();
        
      }, []);
      useEffect(()=>{
        if(userDetails?.testCount?.['Platinum']===10){
            const ctx = canvas.current.getContext("2d");
            let img = new Image();
            img.src = image;
            img.onload = function () {
                console.log("---", img)
              ctx.drawImage(img, 0, 0, 650, 425);
              ctx.font = "13px Arial";
              ctx.fillText(getdate, 380, 370, 200);
              ctx.font = "20px Arial";
              ctx.fillText(userDetails.name, 350, 210, 200, 40);
              ctx.font = " 16px Arial";
              ctx.fillText("Issued Date", 380, 350, 100);
            };}
      },
      [userDetails])
        const getPlanExpiry = async () => {
          var result = await fetchData({
            url: "adminPanel/get-user-profile",
            method: "get",
            headers: {
              token: accountData?.accessToken,
              username: accountData?.user,
            },
          });

          if (result.msg === "success") {
            setPlanExpiry(result.asset.planExpiryDetails);
            setUserDetails(result.asset.userDetails);
          } else {
            setUserDetails(undefined);
            console.log(result);
          }
        };

        useEffect(() => {
          if (alert) {
            setTimeout(() => {
              setAlert("");
            }, 3000);
          }
        }, [alert]);

        async function changedFields(e) {
          e.preventDefault();
          const fields = [
            "college",
            "degree",
            "specialization",
            "mobile",
            "gender",
            "dob",
          ];
          var isFormValid = fields.some((field) => {
            return userDetails[field] && userDetails[field].length > 0;
          });
          if (!isFormValid) {
            setfieldError(true);
            return;
          } else {
            setfieldError(false);
          }
          let toSendEditedData = userDetails;
          console.log(toSendEditedData, "before");
          toSendEditedData["dob"] = new Date(toSendEditedData["dob"]);
          console.log(toSendEditedData, "toSendEditedData");
          const result = await fetchData({
            url: "adminPanel/update-user-profile",
            method: "post",
            headers: {
              token: accountData?.accessToken,
              username: accountData?.user,
            },
            data: toSendEditedData,
          });
          if (result?.msg === "success") {
            setAlert(result.desc || "Profile Updated Successfully");
            addTokenToRedux(
              result.asset.accessToken,
              result.asset.user,
              result.asset.userTypes,
              result.asset.userData
            );
            getPlanExpiry();
          } else {
              setAlert(result.detail || "Something went wrong");
          }
        }
        console.log(userDetails?.testCount, "user");
        return (
          <>
            <style>
              {`
              #profile .userProfileTable  th ,td{
                padding: 10px 12px;
                border: 1px solid black !important;
              }
            `}
            </style>
            <Header accountData={accountData} />
            <div
              className="container rounded bg-white mt-5 mb-5"
              id="profile"
              style={{ display: "flex" }}
            >
              <div className="row">
                <div className="col-md-5 border-right change3">
                  <form
                    className="p-3 py-5 formchange"
                    style={{ width: "130%" }}
                    onSubmit={changedFields}
                  >
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <h4 className="text-right">Profile Settings</h4>
                      {fieldError &&
                        { alert }(
                          <span className="formError">
                            {" "}
                            Some Values are Empty
                          </span>
                        )}
                      <span className="formError">{alert}</span>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-6">
                        <label className="labels">Name</label>
                        <h5>{userDetails?.name}</h5>
                      </div>
                      <div className="col-md-6">
                        <label className="labels">Gender</label>
                        <select
                          style={{ height: "auto", padding: "0 15px" }}
                          name="gender"
                          value={userDetails?.gender}
                          onChange={handleChange}
                          defaultValue=""
                        >
                          <option value="" disabled>
                            Gender
                          </option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Rather Not to Say">
                            Rather Not to Say
                          </option>
                        </select>
                      </div>
                      <div className="col-md-12">
                        <label className="labels">DoB</label>
                        <input
                          type="date"
                          className="form-control"
                          value={userDetails?.dob}
                          name="dob"
                          style={{ fontSize: "15px" }}
                          onChange={handleChange}
                          placeholder="Date of Birth"
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <label className="labels">Mobile Number</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="enter phone number"
                          value={userDetails?.mobile}
                          name="mobile"
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                      <div className="col-md-6">
                        <label className="labels">Email ID</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="enter email id"
                          value={userDetails?.mail}
                          name="mail"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <label className="labels">College</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="College"
                          value={userDetails?.college}
                          name="college"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-6">
                        <label className="labels">Degree</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Degree"
                          value={userDetails?.degree}
                          name="degree"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-6">
                        <label className="labels">Specialization</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Specialization"
                          value={userDetails?.specialization}
                          name="specialization"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <center>
                      <button
                        className="edu-btn w-60 mb--30"
                        style={{
                          height: "43px",
                          lineHeight: "43px",
                          padding: "0 24px",
                          marginTop: "15px",
                        }}
                        type="submit"
                      >
                        <span>Save</span>
                      </button>
                    </center>
                  </form>
                </div>
                <div className="col-md-3 border-right change1">
                  <div className="d-flex flex-column align-items-center text-center p-3 py-5">
                    <img className="rounded-circle mt-5" width="150px" src="" />
                    <span className="font-weight-bold change2">
                      Subscribed Plans
                    </span>
                    <span
                      className="font-weight-bold"
                      style={{ fontSize: "14px" }}
                    >
                      <table className="userProfileTable">
                        <thead>
                          <tr>
                            <th>Plan Name</th>
                            <th>Plan Validity Date</th>
                            <th>Remaining Days</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Free</td>
                            <td>Nil</td>
                            <td>Nil</td>
                          </tr>
                          {planExpiry.map(
                            ({ planName, planValidityDate, remainingDays }) => (
                              <tr>
                                <td>{planName}</td>
                                <td>{planValidityDate}</td>
                                <td>{remainingDays}</td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {userDetails?.testCount?.["Platinum"] == 10 ? (
              <div className="certificateContainer">
                <div id="certify" style={{ clipPath: "circle(0)" }}>
                  <canvas
                    id="canvasImg"
                    ref={canvas}
                    width={640}
                    height={425}
                  />
                </div>
                <div>
                  <button
                    className="edu-btn w-60 mb--30 change4"
                    style={{
                      height: "43px",
                      lineHeight: "43px",
                      padding: "0 24px",
                      marginTop: "15px",
                    }}
                    onClick={pdfDownload}
                  >
                    Download Certificate
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
            <Footer />
          </>
        );
}

const mapDisPatchToProps=(dispatch)=>{
    return {
        addTokenToRedux: (accessToken,username,userTypes,userData)=>dispatch(addToken(accessToken,username,userTypes,userData))
    }
  }
  
  export default connect(null,mapDisPatchToProps)(EditUser);

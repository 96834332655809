import React,{useEffect} from "react"
import image from "../Elements/Header/tym.png"
import {Header} from './Header/header';
import {Footer} from './Footer/footer';

const policyBanner =  "assets/images/bg/privacypolicy.jpg";

const Policy=({accountData})=>{

    useEffect(()=>{
        window.scrollTo({
         top: 0,
         behavior: 'smooth'
        })
      },[])
      
    return(
        <React.Fragment>
            <Header accountData={accountData}/>
            <div className="edu-breadcrumb-area breadcrumb-style-1 ptb--90 ptb_sm--40 bg-image" style={{backgroundImage: `url(${policyBanner})`}}>
            <div className="container eduvibe-animated-shape">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcrumb-inner text-start">
                            <div className="page-title">
                                <h3 className="title"style={{color: "white"}}></h3>
                            </div>
                            <nav className="edu-breadcrumb-nav">
                                <ol className="edu-breadcrumb d-flex justify-content-start liststyle">
                                    <li className="breadcrumb-item"></li>
                                    <li className="separator"></li>
                                    <li className="breadcrumb-item active" aria-current="page"></li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div className="m_edu-privacy-policy-area m_edu-privacy-policy m_edu-section-gap m_bg-color-white">
            <div className="m_container container">
                <div className="m_row m_g-5">
                    <div className="m_content">
                       
                        <h4 className="m_title">Privacy Policy</h4>
                        <p>
Our Privacy Policy and procedures regarding the collection, use, disclosure and protection of information is as follow :Testyourmetrics isanOnlinetest company registered with commitment to protecting and respecting your privacy. This privacy policy (âPrivacy Policyâ) explains how we collect, use, share and protect data that identifies or is associated with you (âpersonal dataâ) in relation to our website www. Testyourmetrics.com</p>

                        <h4 className="m_title">Information Collection And Use:</h4>
                        <p>While using our Site, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to your name ("Personal Information").</p>

                        <h4 className="m_title">Log Data</h4>
                        <p>Like many site operators, we collect information that your browser sends whenever you visit our Site ("Log Data"). This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser type, browser version, the pages of our Site that you visit, the time and date of your visit, the time spent on those pages and other statistics. In addition, we may use third party services such as Google Analytics that collect, monitor and analyze this â¦</p>

                        <h4 className="m_title">Communications </h4>
                        <p>We may use your Personal Information to contact you with newsletters, marketing or promotional materials and other information that ... The Communications section is for businesses that may contact users via email (email newsletters) or other methods. For the full disclosure section, create your own Privacy Policy</p>
                        
                        <h4 className="m_title">Cookies</h4>
                        <p> Cookies are files with small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a web site and stored on your computer's hard drive. Like many sites, we use "cookies" to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Site..</p>

                        <h4 className="m_title">Cookies</h4>
                        <p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Praesent facilisis, ante quis accumsan ultricies, nunc felis fermentum lorem, at lobortis mi erat non ligula. Nulla nisl purus, placerat ut nulla nec, convallis cursus arcu. Sed volutpat ullamcorper diam, at porta mi feugiat sit amet. Maecenas et leo ac tellus aliquet dapibus. Sed rutrum metus eget arcu commodo, non rutrum sem elementum.</p>

                        <h4 className="m_title">Changes To This Privacy Policy </h4>
                        <p>This Privacy Policy is effective as of (add date) and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page. We reserve the right to update or change our Privacy Policy at any time and you should check this Privacy Policy periodically. Your continued use of the Service after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy. If we make any material changes to this Privacy Policy, we will notify you either through the email address you have provided us, or by placing a prominent notice on our website.</p>
                        
                          <h4 className="m_title">Contact Us</h4>
                        <p>If you have any questions about this Privacy Policy, please contact us <a href="mailto:ussupport@testyourmetrics.com" target="_blank" rel="noreferrer">support@testyourmetrics.com</a></p>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
        </React.Fragment>
    )
}
export default Policy;
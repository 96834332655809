import React from 'react';
import Home from '../Home';
import { Header } from '../Elements/Header/header';
import { Footer} from '../Elements/Footer/footer';
export default function Entry({accountData,updateUserInfo}){
    return(
        <>
        <Header accountData={accountData} styles={{position:"absolute"}}/>

    <Home accountData={accountData} updateUserInfo={updateUserInfo}/>
    <Footer />
    </>
    )
}



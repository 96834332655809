import { useState } from "react";
import showPwdImg from "./show-password.svg";
import hidePwdImg from "./hide-password.svg";
import fetchData from "../serverCall/fetchData";
import { useSearchParams,useNavigate } from "react-router-dom";
import { Header } from "../Elements/Header/header";
import { Footer } from "../Elements/Footer/footer";
const Reset = () => {
  const navigate = useNavigate();
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [password, setPassWord] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [validation, setValidation] = useState("");
    const [responseMessage,setResponseMessage] = useState('');

    const [params]=useSearchParams();
   
  async function resetPassword(){ 
      var resPass = await fetchData({
          requestingPage: "ResetPassword",
          method:"post",
          url: "auth/request-new-password",
          data: {mail:params.get('mail'),newPassword: confirmPassword}
      });
      if(resPass && resPass.msg === "success"){
          setResponseMessage(resPass.desc+" You Can Login With New Password ");
          setPassWord('');
          setConfirmPassword('');
          setTimeout(()=> navigate('/login'),2000);
      } else if(resPass && resPass.msg === "failure"){
          setResponseMessage(resPass.desc+" "+resPass.detail);
      } else{setResponseMessage(resPass)}
  }

const PwdValidation =() => {
    if (confirmPassword.length < 8) {
        setValidation ("Password must be at least 8 characters");
    }
    else if (confirmPassword.length > 15){
        setValidation ("Password must be less than 15 characters")
    }
    else if (confirmPassword !== password){
        setValidation ("Password and confirm password must be same")
    }
    else{
      resetPassword()
        return setValidation ("");
    }
}
  return (
    <>
    <Header />
    <div
      className="login-register-page-wrapper login-form-box"
      style={{ marginTop: "25px", marginLeft: "32%", position:"",height:"78vh",width:"35%" }}
    >
        <div className="container checkout-page-style" >
      <div className="login-form-box"  style={{padding:"30px"}}>
        <center><h3 className="mb-30">Reset Password</h3></center>
        <form className="login-form">
                  <div className="input-box mb--30">
        <div className="showHideIcon">
          <h6
            className="mb-25"
            style={{ marginBottom: "2px", fontSize: "15px" }}
          >
            Enter New Password
          </h6>
          <div className="input-box mb--30" style={{ position: "relative" }}>
            <input name="pwd" type="password" value={password}
            onChange={(e) => setPassWord(e.target.value)}/>
          </div>
          <h6
            className="mb-25"
            style={{ marginBottom: "2px", fontSize: "15px" }}
          >
            Retype Password
          </h6>
          <div className="input-box mb--30" style={{ position: "relative" }}>
            <input name="rpwd"   type={isRevealPwd ? "text" : "password"}
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}/>
            <img alt="eye"
                      title={isRevealPwd ? "Hide password" : "Show password"}
                      src={isRevealPwd ? hidePwdImg : showPwdImg}
                      onClick={() => setIsRevealPwd((prevState) => !prevState)}
                    />
          </div>
          <div  style={{marginBottom:"15px"}}>
          {validation}
          </div>
        </div>
        <center>
          <a
            className="rn-btn edu-btn "
            style={{
              width: "85px",
              height: "40px",
              lineHeight: "2.2",
              padding: "0px",
              cursor: "pointer"
            }}
            onClick={PwdValidation}
          >
            Reset
          </a>
          <br/>
          {responseMessage}
        </center>
        </div>
        </form>
      </div>
      </div>
    </div>
    <Footer />
    </>
  );
};
export default Reset;

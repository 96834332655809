 import React, { useEffect, useState } from 'react';
import { tilePageData } from './editPageData';
 import './adminEditPage.css';
import fetchData from '../../serverCall/fetchData';

const TilePage = ({ accountData }) => {
  const [tilePage, setPageData] = useState({});
  const [alert, setAlert] = useState(false);
  const getPlans = async () => {
    var result = await fetchData({
      url: "testPages/get-available-question-sets",
      data: { userTypes: accountData.userTypes },
      headers: { token: accountData.accessToken, user: accountData.user },
      method: "post",
    });

    if (result.msg === "success") {
      setPageData(result.asset);
      setAlert(result.desc);
    } else {
      console.log(result);
      setAlert(result.desc);
    }
  };

  useEffect(() => {
    getPlans();
  }, []);
  const TileBox = ({ set, cssClass, index }) => {
    return (
      <div className={`card ${cssClass}`}>
        <div className="card__body">
          <h1 className="card__id">
            <div className="set">Test-{index + 1}</div>
          </h1>
          <h2 className="card__time" style={{ marginBottom: "5px" }}>
            Duration: {set.testDuration} Min
          </h2>
          <h2 className="card__condition" style={{ marginBottom: "5px" }}>
            No.of.questions: {set.totalQuestions}
          </h2>
          <h2 className="card__condition" style={{ marginBottom: "5px" }}>
            Status:{set.testStatus}
          </h2>
          <p className="card__marks">
            Created Date : {set.createdDate}
            <br />
            Edited Date:
            {set.editedDate}
          </p>
        </div>
        <span className="formError" style={{ textAlign: "center" }}>
          {alert}
        </span>
        <button className="card__btn">Edit</button>
      </div>
    );
  };

  return (
    <>
      <div
        className="adminEditPage"
        style={{ textAlign: "left", margin: "60px", marginLeft: "200px" }}
      >
        {tilePage?.availableTypes?.map((type, i) => (
          <div className="quesSetContainers" key={i}>
            <h1 style={{ fontSize: "30px" }}>{type}</h1>
            <div className="forQuesSet">
              {tilePage[type]?.map((tile, j) => (
                <TileBox key={j} cssClass={`${type}set`} index={j} set={tile} />
              ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default TilePage;



import {Provider} from "react-redux";
import {createStore,applyMiddleware} from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import allReducers from './redux/reduxIndex';
import reduxThunk from "redux-thunk";
import React from 'react';
import RoutePaths from './routers.js';

const MIDDLEWARE=[reduxThunk];
 const STORE=createStore(allReducers,composeWithDevTools(applyMiddleware(...MIDDLEWARE)));

 function App(){
    return(
        <>
    <Provider store={STORE}>
       <RoutePaths />
        </Provider>
      </>
    )
}

export default App;
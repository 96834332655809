import axios from "axios";
import { serverEndpoint } from "./environmentVariables";

const fetchData = ({
    // props
    requestingPage,
    method,
    url,
    data,
    headers,
}) => {
    switch (method) {
        case "post":
            return new Promise((resolve, reject) => {

                axios({
                    requestingPage: requestingPage, method: method,
                    url: serverEndpoint + url, data: data,
                    headers: headers
                })
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((err) => {
                        if (!err.response)
                            resolve("Server Offline Please Try Again Later")
                        else if (err.response?.data?.msg === "failure") {
                            resolve(err.response.data);
                        }
                        else {
                            resolve(err.response);
                        }
                    });

            });

        case "get":
            return new Promise((resolve, reject) => {

                axios({
                    requestingPage: requestingPage, method: method,
                    url: serverEndpoint + url, data: data,
                    headers: headers
                })
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((err) => {
                        if (!err.response)
                            resolve("Server Offline Please Try Again Later")
                        else if (err.response.data?.msg === "failure") {
                            resolve(err.response.data);
                        }
                        else {
                            resolve(err.response);
                        }
                    });

            });
        default:
            return document.write("HTTP Method Not Specified");
    }

}

export default fetchData;
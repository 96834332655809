import { useState } from "react";
import { Link } from "react-router-dom";
import { Header } from "../Elements/Header/header";
import { isEmailValid,isEmpty } from "../utils/util";
import fetchData from "../serverCall/fetchData";

const Forgot = () => {

    const [email, setEmail] = useState('');
    const [validate, setValidate] = useState({});
    const [response,setResponseMessage]=useState('');
  

    async function ForgotPassword(){
        var resPass = await fetchData({
            requestingPage: "ResetPassword",
            method:"post",
            url: "auth/request-password-reset",
            data: {mail: email}
        });
        if(resPass && resPass.msg === "success"){
            setResponseMessage(resPass.desc);
        } else if(resPass.msg === "failure"){
            setResponseMessage(resPass.desc+" "+resPass.detail);
        } else{setResponseMessage(resPass)}
    }

    const forgotPassword = (e) => {
        e.preventDefault();

        if (!email) {
            setValidate({validate: "Email Can't be Empty"})
        } else if(!isEmailValid(email)){
            setValidate({validate: "Invalid Email"})

        }  else{ 
            ForgotPassword()
            setResponseMessage('Reset password link is sent to '+email)
            setValidate({});
            setEmail('');}
      
    }

    return (
        <>
        <Header />
            <div className="login-register-page-wrapper login-form-box col-lg-6" style={{marginTop:"25px",marginLeft:"100px"}}>
                    <div className="login-form-box">
                        <h3 className="mb-30">Reset Password</h3>
                        <div className="auth-form-container" >
                            <form className="login-form" method="POST" onSubmit={forgotPassword} autoComplete={'off'}>
                                <div className="checkout-page-style  mb--30">
                                    <input type="text"
                                        className={`form-control ${validate.validate ? 'is-invalid ' : ''}`}
                                        id="email"
                                        name="email"
                                        value={email}
                                        placeholder="Email"
                                        onChange={(e) => setEmail(e.target.value)}
                                        style={{ width:"400px"}}
                                    />
                                
                                    <div className={`invalid-feedback ${(validate.validate) ? 'd-block' : 'd-none'}`} >
                                        {validate.validate}
                                    </div>
                                    <div style={{marginTop:"10px"}}>
                                    <span>{response}</span>
                                    </div>
                                </div >
                                    <button type="submit" className="rn-btn edu-btn " style={{ width:"200px",height:"50px", lineHeight: "50px"}}>Forgot Password</button>
                            </form> 
                            <div className="auth-option text-center pt-2"><Link className="text-link" to="/login" >Back to Login</Link></div>
                        </div>
                    </div>
                </div>
        </>
    );
}

export default Forgot;
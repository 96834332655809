

import React, { useState, useRef, useEffect } from 'react'

export const getDeadTime = (timeInMinutes) => {
	const endTime = (localStorage.getItem('quizTime') ? new Date(JSON.parse(localStorage.getItem('quizTime'))["endTime"]) : new Date());
	if (!localStorage.getItem('accountData')["testSession"]) {
		endTime.setSeconds(endTime.getSeconds() + timeInMinutes * 60);
	}
	// console.log(endTime,'dd',new Date(JSON.parse(localStorage.getItem('quizTime'))["endTime"]));
	return Date.parse(endTime);
}
const TimerClock = ({ timeInMinutes, timeOutSubmit , signal }) => {

	let id;
	const Ref = useRef(null);
    
	const [timer, setTimer] = useState('');
	const [isSubmitted,setIsSubmitted] = useState(false)
	const endTime = (localStorage.getItem('quizTime') ? new Date(JSON.parse(localStorage.getItem('quizTime'))["endTime"]) : new Date());


	const getTimeRemaining = (e) => {
		const total = e - Date.parse(new Date());
		const seconds = Math.floor((total / 1000) % 60);
		const minutes = Math.floor((total / 1000 / 60) % 60);
		const hours = Math.floor((total / 1000 / 60 / 60) % 24);
		return {
			total, hours, minutes, seconds
		};
	}


	const startTimer = (e) => {
		let { total, hours, minutes, seconds }
			= getTimeRemaining(e);
		if (total > 0 && signal=="start") {
			setTimer(
				(hours > 9 ? hours : '0' + hours) + ':' +
				(minutes > 9 ? minutes : '0' + minutes) + ':'
				+ (seconds > 9 ? seconds : '0' + seconds)
			)
		}
		else {
			
			if(signal=="start" && total===0 && !isSubmitted) {
				timeOutSubmit();
				setIsSubmitted(true);
			}
		}
	}


	const clearTimer = (e) => {

		if (Ref.current) clearInterval(Ref.current);
		id = setInterval(() => {
			startTimer(e);
		}, 1000)
		Ref.current = id;
	}

	const getDeadTime = () => {
		if (!localStorage.getItem('quizTime')) {
			endTime.setSeconds(endTime.getSeconds() + timeInMinutes * 60);
			localStorage.setItem('quizTime', JSON.stringify({ "endTime": Date.parse(endTime) }))
		}
		return Date.parse(endTime);
	}


	useEffect(() => {
		if (timeInMinutes !== '')
			clearTimer(getDeadTime());
		// eslint-disable-next-line
	}, [timeInMinutes]);


	return (
		// <div className="App">
		<span>{timer}</span>
		// </div>
	)
}

export { TimerClock };

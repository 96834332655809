import React, { useState, useEffect } from "react";
// import "./questions.css";
import {
  setStoreValue,
  getStoreValue,
  getStoreValueByKey,
  clearStoreValue,
} from "./cache";

function MainContainer(props) {
  const [unsel, setUnsel] = useState("");

  return (
    <React.Fragment>
      <div className="mainContainer">
        {/* <Sections /> */}
        <div className="questioniarre">
          <div>
            {props.data?.map((que, i) => (
              <div key={i} id={i + 1}>
                <Questioniarre question={que} />
              </div>
            ))}
          </div>
          <div className="questionnaireFooter">
            {/*--------------------Next-Btn------------------------ */}
            <span className="unselectedError">
              {unsel ? `Questions ${unsel} Not selected` : " "}
            </span>
            <a
              className="edu-btn next-btn" >
              <span
                onClick={() => {
                  let clickedVal = getStoreValue();
                  const unSelectedQues = props.data
                    .filter(
                      (value, i) =>
                        clickedVal[value["questionId"]] === undefined
                    )
                    .map((val, i) => val["questionNum"]);
                  if (unSelectedQues.length === 0) {
                    setUnsel("");
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                    props.next({
                      storeVal: clickedVal,
                    });
                    clearStoreValue();
                  } else {
                    setUnsel(unSelectedQues.join(","));
                  }
                }}
              >
                {props.buttonState}
                {props.buttonState === "Next" && (
                  <i className="icon-arrow-right-line-right"></i>
                )}
              </span>
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

// -------------Questioniarre_comp----------------

const Questioniarre = ({ question }) => {
  const [selection, setSelection] = useState(getStoreValue());
  const handleSelection = (key, value) => {
    setStoreValue(key, value);
    setSelection({ key: value });
  };
  useEffect(() => {}, [selection]);
  return (
    <>
      <div className="questions">
        <span style={{ margin: "5px" }}>{question.questionNum}. </span>{" "}
        {question.question}
      </div>
      <div>
        <OptionHandle
          handler={handleSelection}
          quesIdx={question.questionId}
          options={question.options}
        />
      </div>
    </>
  );
};

// -----------------OptionHandle_comp--------------

const OptionHandle = ({ options, quesIdx, handler }) => {
  var listOfOptions = options?.map((op, i) => {
    var custom =
      getStoreValueByKey(quesIdx) == String.fromCharCode(65 + i)
        ? "selected"
        : "list";
    var customClass = custom;
    return (
      <li
        className={customClass}
        data-answer-code={String.fromCharCode(65 + i)}
        key={i}
        id={i}
      >
        {op}
      </li>
    );
  });

  return (
    <React.Fragment>
      <ol
        onClick={(e) => {
          handler(quesIdx, e.target.getAttribute("data-answer-code"));
        }}
        className="options"
      >
        {listOfOptions}
      </ol>
    </React.Fragment>
  );
};

export default MainContainer;

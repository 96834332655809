import React from "react";
import "./popup.css";

const Popup = ({
  defaultButtons = { ok: true, close: true, cancel: true },
  getSetId,
  ProceedButton,
  cancelButton,
  styles,
  Content
}) => {
  let { ok, close, cancel } = defaultButtons;
  return (
    <div className="popup-box modal-fade">
      <div className="box"  style={styles}>
            {Content}
              <center>
              {ProceedButton}
              {cancelButton}
              </center>
      </div>
    </div>
  );
};

export default Popup;


import { Link } from "react-router-dom";
import React, { useState,useEffect } from "react";
//  import { testPageData } from "./TestPageData";
import "./TestPagenew.css";
import { Header } from "../../Elements/Header/header";
 import { Footer } from "../../Elements/Footer/footer";
import fetchData from "../../serverCall/fetchData";
import CustomPopup from "../../Elements/popup/popup1";

const TilePage = ({ accountData }) => {
 const [tilePage,setPageData]=useState({})

  // popup hooks
 const [popupContent, setPopupContent] = useState('');
 const [popup, setPopup] = useState(false);
 const [popupTitle,setPopupTitle] = useState(" Starting Test");


  const getTestSets=async()=>{
    var result = await fetchData({
      url :'testPages/get-available-question-sets',
      data:  {userTypes: accountData?.userTypes},
      headers: {token:accountData?.accessToken, user: accountData?.user},
      method: 'post',
    });

    if(result.msg==='success'){
      setPageData(result.asset);
    }else{
 console.log(result);
    }
  }

  useEffect(()=>{
    getTestSets();  
    localStorage.removeItem("quizTime");
  },[])
    
  const TileBox = ({ set, cssClass,index }) => {
   
    return (
     <React.Fragment>
      
      <div className={`testPageCard card ${cssClass}`}>
        <div className="card__body">
          <h1 className="card__id">
            <div className="set">Test {index+1}</div>
            <div style={{fontSize:"16px",fontWeight:"600"}}>Quantitative | Logical | Aptitude</div>
          </h1>
          <h2 className="card__time" style={{ marginBottom: "5px" }}>
            Duration: {set.testDuration} Min
          </h2>
          <h2 className="card__condition" style={{ marginBottom: "5px" }}>
            No.of.questions:{set.totalQuestions}
          </h2>
          <h2 className="card__condition" style={{ marginBottom: "5px" }}>
            Status:{set.testResult ? 'Attended' : 'Not Attended'}
          </h2>
          {set.testResult ? (
            <span  className="card__marks">
              {" "}
              Scored Marks: {set.testResult.securedMarks}
            </span>
          ) : (
            ""
          )}
        </div>
        {set.testResult ? (
          <span  className="card__btn1" onClick={()=>{
            setPopupContent(<ViewReport set={set} index={index}/>)
            setPopup(true);
            setPopupTitle("Test Result of Set " + (index +1));
          }}>
            VIEW REPORT
          </span>
        ) : (
          <Link to={set.testId} className="card__btn" onClick={()=>localStorage.removeItem("quizTime")}>
            TAKE TEST
          </Link>
        )}
      </div>
      </React.Fragment>
    );

  };

  const ViewReport=({set,index})=>{
  
  return (<div className="text-center card__body">
             <div className="card__id">
             <div className="set">Plan: {set.testType}</div>
           </div>

           <ul className="list-group list-group-flush">
           <div className="list-group-item" style={{ marginBottom: "5px" }}>
            Duration: {set.testDuration} Mins
           </div>
          
            <div className="list-group-item">Test Attended Date: {set.testResult.testEndTime}</div>
            <div className="list-group-item">Total Questions: {set.testResult.totalQuestions}</div>
            <div className="list-group-item">Scored Marks: {set.testResult.securedMarks} / {parseInt(set.testResult.totalMarks)}</div>
            
           </ul>
           <div className="popup-actions">
            <button className="popupbtn" id="cancel" onClick={()=> setPopup(false)}> Close </button>
         </div>
            </div>);
  }


  

  // let { availableTypes } = testPageData;
  if(!tilePage || !tilePage.availableTypes) return ;
  return (
    <>
      {popup &&
        <CustomPopup
          show={popup}
          title={popupTitle}>
          {popupContent}
        </CustomPopup>
      }
      <Header accountData={accountData} />
      <div className="test" style={{height:"75vh",overflowY:'scroll'}}>
        <div>
          {tilePage?.availableTypes?.map((type, i) => {
            if (tilePage[type].length)
              return <div className="quesSetContainers" key={i}>
                <h1 style={{ fontSize: "30px" }}>{type}</h1>
                <div className="forQuesSet">
                  {tilePage[type].map((tile, j) => (
                    <TileBox key={j} cssClass={`${type}set`} index={j} set={tile} />
                  ))}
                </div>
              </div>
            else
              return <div className="text-center ptb--90">No <strong>{type}</strong> Test Sets are <strong>Active</strong></div>
          })}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TilePage;

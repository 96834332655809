import React,{useEffect} from "react";
import aboutCircle1 from '../assets/images/instructor/instructor-01/instructor-1.jpg'
import aboutCircle2 from '../assets/images/instructor/instructor-01/instructor-2.jpg'
import aboutCircle3 from '../assets/images/instructor/instructor-01/instructor-3.jpg'
import aboutCircle4 from '../assets/images/instructor/instructor-01/instructor-4.jpg'
import aboutCircle5 from '../assets/images/instructor/instructor-01/instructor-5.jpg'
import { Header } from "./Header/header";
import { Footer } from "./Footer/footer";

const aboutUSBanner = "assets/images/bg/about-us-breadcrumb.jpg";

const AboutUs=({accountData})=>{
    useEffect(()=>{
        window.scrollTo({
         top: 0,
         behavior: 'smooth'
        })
      },[])
    return(
        <>
        <React.Fragment>
            <Header accountData={accountData} />
             <div className="edu-breadcrumb-area breadcrumb-style-1 ptb--90 bg-image" style={{backgroundImage: `url(${aboutUSBanner})`}}>
            <div className="container eduvibe-animated-shape">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcrumb-inner text-start">
                            <div className="page-title">
                                <h3 className="title"style={{color: "white"}}>About Us</h3>
                            </div>
                            <nav className="edu-breadcrumb-nav">
                                <ol className="edu-breadcrumb d-flex justify-content-start liststyle">
                                    <li className="breadcrumb-item"></li>
                                    <li className="separator"></li>
                                    <li className="breadcrumb-item active" aria-current="page"></li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div className=" eduvibe-home-four-about edu-about-area about-style-2 edu-section-gap bg-color-white">
            <div className="container eduvibe-animated-shape">
                <div className="row row--50">
                    <div className="col-lg-6">
                        <div className="about-image-gallery">
                            <div className="eduvibe-about-1-img-wrapper">
                                <img className="image-1" src="assets/images/about/about-07/about-image-01.png" alt="About Images" />
                                <span className="eduvibe-about-blur"><img src="assets/images/about/about-07/about-blur.png" alt="About Blur" /></span>
                            </div>
                            <div className="circle-image-wrapper">
                                <img className="image-2" src="assets/images/about/about-07/about-image-02.png" alt="About Images" />
                               
                            </div>
                            <div className="finished-ssession">
                                <div className="inner">
                                    
                                    <span className="finissed-title">
                             
                            </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="inner mt_md--40 mt_sm--40">
                            <div className="section-title"// data-sal-delay="150" data-sal="slide-up" data-sal-duration="800"
                            >
                                <span className="pre-title">About Us</span>
                                <h3 className="title">ONE STOP SOLUTION FOR YOUR CAMPUS PLACEMENT PREPARATION </h3>
                            </div>
                            <p align="justify" className="description mt--40 mt_md--20 mt_sm--20" //data-sal-delay="150" data-sal="slide-up" data-sal-duration="800"
                            > Your dream jobs can be achieved only by proper planning and
                            execution because no one can help you better than you.
                            Campus Hiring is an important phase of your 16 year
                            education few of us have dream companies and for few job
                            itself would be a dream. TestyourMetrics is a platform to
                            get you closer to your dreams. Cracking aptitude is the
                            important part of Campus Hiring. We are team of experts
                            building the aptitude content according to the industry
                            needs. Each Question paper is well researched and customized
                            to give you the confidence in cracking the aptitude. Our
                            timely update on each set of QP will give enough exposure to
                            land in your dream job.    
                            </p>                 
                      </div>
                       </div>
                    </div>
                </div>
            </div>
        
        <div className="eduvibe-about-one-team edu-team-area edu-section-gap team-area-shape-position bg-image bg-image--8 paralax-area">
            <div className="wrapper">
                <div className="container eduvibe-animated-shape">
                    <div className="row g-5">
                        <div className="col-lg-12">
                            <div className="section-title text-center" //data-sal-delay="150" data-sal="slide-up" data-sal-duration="800"
                            >
                                <span className="pre-title">TEST YOUR METRICS</span>
                                <h3 className="title">HOW CAMPUS PLACEMENT PREPARATION WORKS</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row row--20">
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt--45 aboutUsCircle"//data-sal-delay="150" data-sal="slide-up" data-sal-duration="800"
                        >
                            <div className="edu-instructor-grid edu-instructor-1 edu-instructor-1">
                                <div className="edu-instructor">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <a href="instructor-profile.html">
                                                <img src={aboutCircle1} alt="team images"/>
                                            </a>
                                        </div>
                                         </div>
                                </div>
                                <div className="edu-instructor-info">
                                    <h5 className="title"><a href="instructor-profile.html" style={{fontSize:"18px"}}>PRACTICE</a></h5>
                                   
                                </div>
                            </div>
                        </div>
                       
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt--45 aboutUsCircle"  //data-sal-delay="200" data-sal="slide-up" data-sal-duration="800"
                        >
                            <div className="edu-instructor-grid edu-instructor-1">
                                <div className="edu-instructor">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <a href="instructor-profile.html">
                                                <img src={aboutCircle2} alt="team images"/>
                                            </a>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="edu-instructor-info">
                                    <h5 className="title"><a href="instructor-profile.html" style={{fontSize:"18px"}}>RESULT</a></h5>
                                    
                                </div>
                            </div>
                        </div>
                       
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt--45 aboutUsCircle"  //data-sal-delay="250" data-sal="slide-up" data-sal-duration="800"
                        >
                            <div className="edu-instructor-grid edu-instructor-1">
                                <div className="edu-instructor">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <a href="instructor-profile.html">
                                                <img src={aboutCircle3} alt="team images"/>
                                            </a>
                                        </div>
                                       
                                    </div>
                                </div>
                                <div className="edu-instructor-info">
                                    <h5 className="title"><a href="instructor-profile.html" style={{fontSize:"18px"}}>ANALYSE</a></h5>
                                   
                                </div>
                            </div>
                        </div>
                     
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt--45 aboutUsCircle" // data-sal-delay="300" data-sal="slide-up" data-sal-duration="800"
                        >
                            <div className="edu-instructor-grid edu-instructor-1">
                                <div className="edu-instructor">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <a href="instructor-profile.html">
                                                <img src={aboutCircle4} alt="team images"/>
                                            </a>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="edu-instructor-info">
                                    <h5 className="title"><a href="instructor-profile.html" style={{fontSize:"18px"}}>IMPROVISE </a></h5>
                                 
                                </div>
                            </div>
                        </div>
                     
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt--45 aboutUsCircle" //data-sal-delay="350" data-sal="slide-up" data-sal-duration="800"
                        >
                            <div className="edu-instructor-grid edu-instructor-1">
                                <div className="edu-instructor">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <a href="instructor-profile.html">
                                                <img src={aboutCircle5} alt="team images"/>
                                            </a>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="edu-instructor-info">
                                    <h5 className="title"><a href="instructor-profile.html" style={{fontSize:"18px"}}>LAND IN UR DREAM JOB</a></h5>
                                    
                                </div>
                            </div>
                        </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="edu-feature-area eduvibe-home-one-video edu-section-gap bg-color-white">
        <div className="container eduvibe-animated-shape">
            <div className="rowl">
                <div className="cb">
                    <div className="inner mt_md--40 mt_sm--40">
                        <div className="section-title text-start" >
                            <center className="pre-title" style={{display: "block"}}>Why Choose US</center>
                            <h3 className="title text-center">Question papers are collectively chosen from previous placement seasons of various companies.</h3>
                        </div>
                        <div className="feature-list-wrapper mt--10" style={{flexWrap: "unset"}}>
                            <div className="feature-list mt--35 mt_mobile--15">
                                <div className="icon">
                                    <i className="icon-student"></i>
                                </div>
                                <div className="content">
                                    <p>A detailed study is done on frequent Q & A
                                    </p>
                                </div>
                            </div>
                            <div className="feature-list mt--35 mt_mobile--15" >
                                <div className="icon">
                                    <i className="icon-square"></i>
                                </div>
                                <div className="content">
                                    <p>Tailor-made question papers for big brands.
                                    </p>
                                </div>
                            </div>
                            <div className="feature-list mt--35 mt_mobile--15" >
                                <div className="icon">
                                    <i className="icon-research"></i>
                                </div>
                                <div className="content">
                                    <p>Training material for hassle-free preparation.
                                    </p>
                                </div>
                            </div>
                            <div className="feature-list mt--35 mt_mobile--15" >
                                <div className="icon">
                                    <i className="icon-clock"></i>
                                </div>
                                <div className="content">
                                    <p>Simple login credentials and 24/7 chat support.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
                
        </React.Fragment>
        <Footer />
        </>
    )
}

export default AboutUs;
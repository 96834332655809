import { useEffect, useState } from "react";
import popupStyles from "./popup1.css";
// import PropTypes from "prop-types";

const CustomPopup = (props) => {
  const [show, setShow] = useState(false);
  const [customClass,setCustomClass] = useState("popupTitle")
  const closeHandler = (e) => {
    setShow(false);
    props.onClose(false);
  };
  
  useEffect(()=> {
     setCustomClass(props.customClass || "popupTitle");
  },[props.customClass])

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);
 
  return (
    <div
      style={{
        visibility: show ? "visible" : "hidden",
        opacity: show ? "1" : "0"
      }}
      className="overlay"
    >
      <div className="popup">
        <h4 className={customClass}>{props.title}</h4>
        { /*false && <span className="close" onClick={closeHandler}> }
          &times;
        </span> */} 
        <div className="content">{props.children}</div>
      </div>
    </div>
  );
};

export default CustomPopup;
import { useEffect, useState } from "react";
import fetchData from "../../serverCall/fetchData";
import "./fbpage.css";

function Feedback({ accountData }) {
  const [currentValue, setCurrentValue] = useState(0);
  const [hoverValue, setHoverValue] = useState(undefined);
  const [count, setCount] = useState(0);
  const [alert, setAlert] = useState(false);
  const stars = Array(5).fill(0);
  const [data, setData] = useState({});
  const feedbackLimit = 200;

  const handleClick = (values) => {
    setCurrentValue(values);
    setData({ ...data, rating: values });
  };

  const handleMouseOver = (newHoverValue) => {
    setHoverValue(newHoverValue);
  };

  const handleMouseLeave = () => {
    setHoverValue(undefined);
  };

  const handleChange = (e) => {
    if (e.target.name === "feedback") {
      if (count < feedbackLimit) {
        setCount(e.target.value.length);
        setData({ ...data, [e.target.name]: e.target.value });
      }
    } else {
      setData({ ...data, [e.target.name]: e.target.value });
    }
  };

  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert("");
      }, 3000);
    }
  }, [alert]);
  async function getFeedback(e) {
    e.preventDefault();
    const fields = ["name", "department", "college", "feedback", "rating"];

    if (data["rating"] === undefined) {
      setAlert("please give a rating");
    } else if (data.feedback.length > 200) {
      setAlert("feedback should be less than 200 characters");
    } else {
      var feedback = await fetchData({
        requestingPage: "adminPanel",
        method: "post",
        url: "adminPanel/create-feedback",
        data: data,
        headers: {
          token: accountData?.accessToken,
          username: accountData?.user,
        },
      });
      if (feedback && feedback.msg === "success") {
        console.log(data);
        setData("");
        setCount(0);
        setHoverValue(undefined);
        setCurrentValue(0);
        setAlert(feedback.desc);
      }
    }
  }
  return (
    <>
      <div className="fbcontainer">
        <form onSubmit={getFeedback}>
          <h2 style={{ fontSize: 50 }}>Rating and Feedback </h2>
          <div className="Name">
            <label>Name: </label>
            <input
              type="text"
              name="name"
              value={data.name || ""}
              onChange={handleChange}
              required
            ></input>
          </div>
          <div className="department">
            <label>Department: </label>
            <input
              type="text"
              name="department"
              value={data.department || ""}
              onChange={handleChange}
              required
            ></input>
          </div>
          <div className="college">
            <label>college: </label>
            <input
              type="text"
              name="college"
              value={data.college || ""}
              onChange={handleChange}
              required
            ></input>
          </div>
          <div>
            <label>Feedback: </label>
            <textarea
              className="feedback"
              placeholder="Enter your feedback here... not more than 200 characters"
              name="feedback"
              onChange={handleChange}
              value={data.feedback || ""}
              required
            />
            <div
              style={{
                float: "right",
                color: "red",
              }}
            >
              {count}of {feedbackLimit}
            </div>
          </div>
          <div className="stars">
            {stars.map((_, index) => {
              return (
                <i
                  className={`${
                    (hoverValue || currentValue) > index ? "on" : "off"
                  } icon-Star`}
                  key={index}
                  size={50}
                  name="rating"
                  onClick={() => handleClick(index + 1)}
                  onMouseOver={() => handleMouseOver(index + 1)}
                  onMouseLeave={handleMouseLeave}
                />
              );
            })}
            <span
              className="formError"
              style={{ testAlign: "center", color: "green" }}
            >
              {alert}
            </span>
          </div>
          <div>
            <button
              type="submit"
              value="submit"
              className="edu-btn btn-medium left-icon header-button"
              style={{ height: 40, lineHeight: "40px" }}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default Feedback;

import * as FileSaver from "file-saver";
import { useEffect,useState } from "react";
import * as XLSX from "xlsx";
import fetchData from "../../serverCall/fetchData";

export const exportToCSV = (apiData, fileName,filterValue) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const updatedData = apiData.map((lines) => {
    
      delete lines['usertypes'];//deleting the 'usertypes' object key and value.
      delete lines['planDetails']
      lines ['RegisteredDate'] = new Date(lines['RegisteredDate']).toLocaleString() ;
      if(lines["TestID"]!=null)
      {
        lines ['CompletedTime'] = new Date(lines['CompletedTime']).toLocaleString() ;
        console.log(lines ['CompletedTime'])
      }
      else
      {
        lines ['CompletedTime'] = "NA" ;
        lines ['Marks'] = "NA" ;
        lines ['TestID'] = "Not Attended";
      }
      return lines;

  })
  console.log('...',filterValue)
  console.log(updatedData)
  const ws = XLSX.utils.json_to_sheet(updatedData);

  let wb = { Sheets: { "User Audit": ws }, SheetNames: ["User Audit"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);

};

  



      
import React, { useEffect, useState } from "react";
import MainContainer from "./questions";
import { getDeadTime } from "./timer";
import { addTestSession } from "../../redux/loginData";
import { connect } from "react-redux";
import {useParams} from 'react-router-dom';
import fetchData from "../../serverCall/fetchData";
import {TimerClock} from './timer';
import './question.css';
import tymLogo from './tym.png';
import { useNavigate } from "react-router-dom";
import CustomPopup from "../../Elements/popup/popup1";
import { quizConst } from "../../utils/constants";
function ExamPage({accountData, testSessionObject, subscribeTest }) {
  
  const navigate = useNavigate();
 
  const [questionnaire, setQuestionnaire] = useState([]);
  const [sections, setSections] = useState([]);
  const [storedVal, setStoredVal] = useState();
  const [currentSectionInd,setCurrentSectionInd]=useState(0);
  const {testId} = useParams();
  //const [currentSectionInd,setCurrentSectionInd]=useState(testSessionObject?.sectionsWithStatus?.findIndex((sec)=>sec?.status==='Current') || 0);
  const [currentSectionQuestion,setCurrentSectionQuestion] = useState([]);
  const [testTime,setTestTime]=useState('');
  const [signal, setSignal] = useState("start");
 // popup hooks
 const [popupContent, setPopupContent] = useState('');
 const [popup, setPopup] = useState(false);
 const [popupTitle,setPopupTitle] = useState(" Starting Test");
 const [customClass, setCustomClass] = useState("popupTitle");

  const getErrorPopupContent=({msg, desc, detail})=> {
    return (
      <div className="test-instructions">
          <h3 className="d-block test-instructions__title">{msg}-{desc}</h3>
          <div id="instructions-content">
              <strong>{detail} </strong>
          </div>
          <div className="popup-actions">
            <button className="popupbtn big alert" id="error" onClick={popupButtonHandler}> Redirect to Test Set page </button>
         </div>
        </div>
    )
  }

 const testStartPopup = () => {
   const instructionPopup=()=> (
     <div className="test-instructions">
       <h3 className="d-block test-instructions__title">Instructions</h3>
       <div className="candidate-rich-text">
         <div>
           <div id="instructions-content">
          
             <ol>
               <li>
                 This is a timed test. Please make sure you are not interrupted
                 during the test, as the timer cannot be paused once started.
               </li>
               <li>Please ensure you have a stable internet connection.</li>
               <li><strong> Please Do not refresh , else test will be auto submitted </strong></li>
               <li>
                 If you have changed your mind you can click cancel to exit the
                 test
               </li>
             </ol>
           </div>
         </div>
         <div className="popup-actions">
            <button className="popupbtn" id="start" onClick={popupButtonHandler}> Proceed </button> 
            <button className="popupbtn" id="cancel" onClick={popupButtonHandler}> Cancel </button>
         </div>
       </div>
     </div>
   );
   setPopupContent(instructionPopup)
   setPopup(true);
   setCustomClass("popupTitle")
   let arr=testId.split("-");
   const [set,plan] =  [arr[1],arr[2]] ;
   setPopupTitle("Starting Test "+ plan + "- Set " + set);;
 }
  useEffect(()=>{
    testStartPopup();
  },[])
 
  const Header = ({ timeInMins,testName }) => {
    return (
      <>
      <div className="header" >
        <div className="imgdiv" >
          <img  src={tymLogo} />
        </div>
      <div>
        <div className="testTitle">{ testName[2] + "- Set " + testName[1]}</div>
        <br/>
        <div className="testTitle">Quantitative | Logical | Aptitude</div>
        </div>
        {signal === "start" && <div className="timer" style={{ fontSize: "25px" }}>
          {" "}
          <TimerClock timeInMinutes={timeInMins} timeOutSubmit={timeOutSubmit} signal={signal} />
        </div>
        }
      </div>
      </>
    );
  };

  async function getSetByTestId(testId){
    if (questionnaire.length === 0) {
      var result = await fetchData({
        url: "testPages/get-test-set",
        method: "post",
        headers: { token: accountData?.accessToken, user: accountData.user },
        data: { testId },
      });
     
     console.log("result", result);

      if(result.msg === quizConst.FAILURE) {
        setPopupContent(getErrorPopupContent(result))
        setPopup(true);
        setPopupTitle(quizConst.FAILURE);
        setCustomClass("popupTitle failure");
        return []; //empty array to invalidate the section
      }

      if (result.msg === quizConst.SUCCESS) {
        const Sections = result.asset.Sections;
        const availableSections = result.asset.availableSections;
        const currentSectionName = availableSections[currentSectionInd].sectionName;
        const CurrentSection = Sections[currentSectionName];
        setSections(availableSections.map((sect) => sect.sectionName));
        setQuestionnaire(result.asset);
        setCurrentSectionQuestion(CurrentSection);
        /*For dev*/ setTestTime(result.asset.testDurationInMins); //result.asset.testDurationInMins
        return await result.asset;
      } else {
        // setQuestionnaire(questionData)
        return questionnaire;
      }
    } 
  }

const launchTest=(testId)=>{
    if ( testId!=="" && questionnaire.length === 0) {
    
      let init;
      (async()=>{
      await getSetByTestId(testId)
      .then(t=>{ console.log("ttttttt",t);init = t;})
      .catch(err=>{console.log("Error in fetching data", err)});

      if(init){
      subscribeTest({
        testId: init?.testId,
        testEndTime: getDeadTime(testTime),
        sectionsWithStatus:init?.availableSections,
      });
    }
     
    })();

    } else if (sections && sections.length !== 0) {
      const currentSectionName = sections[currentSectionInd]
        setCurrentSectionQuestion(questionnaire["Sections"][currentSectionName])

    }
  }

  const goToNext = (sectionsArray, nextSectionIndex,answerValues) => {
        sectionsArray=sectionsArray.map((sec,ind)=>{
        return {
          sectionName: sec,
          status: ind < nextSectionIndex ? 'Completed' : 'Not Attended',
        }}
        )
    if (sections[nextSectionIndex-1]) {
      const currentSectionName = sections[nextSectionIndex];
      const previousSectionName = sections[nextSectionIndex-1];
      sectionsArray.forEach(element => {
        if(element.sectionName===previousSectionName)
          element["answerValues"] = answerValues;
      });
      subscribeTest({sectionsWithStatus: sectionsArray,[previousSectionName]:answerValues});
      setCurrentSectionInd(nextSectionIndex)
      setCurrentSectionQuestion(questionnaire["Sections"][currentSectionName])
     let completed = sectionsArray.filter((row)=>row.sectionName===previousSectionName);
      submitSections(completed);

      
    } 

    if (getButtonState() === "Submit") {
      
      const  endTestpopupContent = () => (
        <div>
          <div>
                <p> Your Test has been successfully completed </p>
          </div>
          <div className="popup-actions">
             <button className="popupbtn" id="end" onClick={popupButtonHandler}> End Test </button> 
          </div>
        </div>
        
      )
      setPopupContent(endTestpopupContent);
      setPopup(true);
      setCustomClass("popupTitle")
      setPopupTitle("End Test");
    }
     
    
  };

  const popupButtonHandler = (e) => {
    const type = e.target.id;
    if (type === "start") {
      setPopup(false); 
      launchTest(testId) }
    
    else if(type === "end" || type === "cancel" || type==="error") {
      navigate('/test');
    } 
  }

  const submitSections = async (completed) => {
    console.log("Timeup compeleted", completed)
    var result = await fetchData({
      url: 'testPages/submit-section',
      data: {
        testSessionData: {
          completedSections: completed,
          testId: testId,
          testStatus: getButtonState() === 'Submit' ? 'Completed' : 'In Progress',
          testCompletedTime : getButtonState() === 'Submit' ? Date.now() : '',
        }
      },
      headers: { token: accountData.accessToken, user: accountData.user },
      method: 'post',
    })

    if(result.msg===quizConst.SUCCESS){
      if(getButtonState()=== "Submit") localStorage.removeItem(quizConst.LOCAL_QUIZ_TIME);
    }else{
      console.log(result);
      localStorage.removeItem(quizConst.LOCAL_QUIZ_TIME);
    }
  }
  const timeOutSubmit = async () => {
    const completed =[
    {
      sectionName: sections[currentSectionInd],
      status: "Completed",
      answerValues: {}
    }]
    localStorage.removeItem(quizConst.LOCAL_QUIZ_TIME);
    var result = await fetchData({
      url: 'testPages/submit-section',
      data: {
        testSessionData: {
          completedSections: completed,
          testId: testId,
          testStatus: 'Completed',
          testCompletedTime: Date.now(),
        }
      },
      headers: { token: accountData.accessToken, user: accountData.user },
      method: 'post',
    });
    if (result.msg === 'success') {
      const  endTestpopupContent = () => (
        <div>
          <div>
                <p> You have reached the time limit set for the quiz.The Quiz  has been submitted successfully </p>
          </div>
          <div className="popup-actions">
             <button className="popupbtn" id="end" onClick={popupButtonHandler}> End Test </button> 
          </div>
        </div>
        
      )
      setPopupContent(endTestpopupContent);
      setPopup(true);
      setCustomClass("popupTitle");
      setPopupTitle("End Test");
    } else {
      console.log(result);
    }

    
    setSignal("stop");

  }

  const getButtonState=()=>{
    if(sections[currentSectionInd+1])
    {
      return "Next"
    }
    else{
      return "Submit"
    }
  }



  return (
    <React.Fragment>
   {popup &&
        <CustomPopup
          show={popup}
          title={popupTitle}
          customClass={customClass}>
          {popupContent}
        </CustomPopup>
      }
      <>
      <Header timeInMins={testTime} testName={testId.split('-')}/>
      </>
      <div className="questiondiv">
      <MainContainer
        data={currentSectionQuestion}
        Sections={sections}
        testEnds={testTime}
        buttonState={getButtonState()}
        next={(value) => {
          goToNext(sections,currentSectionInd+1,value.storeVal);
          setStoredVal(value.storeVal);
        }}
      />
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return { accountData : state.accountData,
    testSessionObject: state.accountData.testSession };
};

const mapDispatchToProps = (dispatch) => {
  return {
    subscribeTest: (testSessionObject) =>
      dispatch(addTestSession(testSessionObject)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ExamPage);

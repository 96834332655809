import React, {useState, useEffect} from "react";
// import { feedbackContent } from "../../../Elements/feedback/feedback";
import "./feedBackList.css";
import fetchData from "../../../serverCall/fetchData";
// import { post } from "jquery";
import {quizConst} from "../../../utils/constants";

const listItems = [
  {id: "notApproved", name: "Un Approved Feed Back"},
  {id: "approved", name: "Approved Feed Back"},
];

export const FeedBackList = ({accountData}) => {
  const [section, setSection] = useState("notApproved");
  const [approvedApi, setApprovedApi] = useState([]);
  const [notApprovedApi, setNotApprovedApi] = useState([]);
  const feedItems = ["name", "degree", "college", "rating"];
  const [checkList, setCheckList] = useState([]);

  const callListAPIs = async section => {
    setCheckList([]);
    switch (section) {
      case "notApproved":
        let apiUnApproved = await getFeedBack("Approval Pending");
        // console.log("apiun",apiUnApproved);
        setNotApprovedApi(apiUnApproved);
        // getFeedBack("Approval Pending");
        break;

      case "approved":
        let apiApproved = await getFeedBack("Approved");
        console.log("appro", apiApproved);
        setApprovedApi(apiApproved);
        // getFeedBack("Approved");
        break;

      default:
        return;
    }
  };
  useEffect(() => {
    callListAPIs(section);
  }, [section]);

  async function getFeedBack(feedBackStatus) {
    var getList = await fetchData({
      url: "adminPanel/get-feedback",
      data: {feedBackStatus},
      method: "post",
      headers: {
        token: accountData.accessToken,
        user: accountData.user,
      },
    });
    if (getList && getList.msg == quizConst.SUCCESS)
      return getList.asset.map((item, id) => {
        item.collapse = true;
        return item;
      });
    else return [];
  }

  async function updateFeed(feedBackStatus) {
    var getList = await fetchData({
      url: "adminPanel/update-feedback",
      data: {feedBackStatus, feedBackIds: checkList},
      method: "post",
      headers: {
        token: accountData.accessToken,
        user: accountData.user,
      },
    });
    if (getList && getList.msg == quizConst.SUCCESS) {
      callListAPIs(section);
    } else return [];
  }

  // ----------------collapse function---------------------
  const contentCollapse = (list, index) => {
    let listCopy = [];

    switch (list) {
      case "notApproved":
        listCopy = notApprovedApi.slice();
        break;

      case "approved":
        listCopy = approvedApi.slice();
        break;

      default:
        return;
    }
    listCopy.forEach((item, j) => {
      if (j === index) {
        listCopy[j].collapse = !item.collapse;
      } else listCopy[j].collapse = true;
    });

    switch (list) {
      case "notApproved":
        setNotApprovedApi(listCopy);
        break;

      case "approved":
        setApprovedApi(listCopy);
        break;

      default:
        return;
    }
  };

  // ------------------table-------------
  const createTable = () => {
    // -----------checkbox-------------
    const handleChecked = (feedBackList, checked) => {
      if (checked) {
        setCheckList([...checkList, feedBackList]);
      } else {
        let copyCheckList = checkList;
        let unChecked = copyCheckList.filter(listId => listId !== feedBackList);

        setCheckList(unChecked);
      }
    };

    switch (section) {
      case "notApproved":
        return (
          <React.Fragment>
            <table className="mainTable" style={{width: "97%"}}>
              <thead className="tableHead">
                <tr>
                  <td className="tableHead">Name</td>
                  <td className="tableHead">Degree</td>
                  <td className="tableHead">College</td>
                  <td className="tableHead">Rating</td>
                  <td className="tableHead">Select</td>
                </tr>
              </thead>
              <tbody>
                {notApprovedApi.map((items, id) => {
                  // console.log("itm",items)
                  return (
                    <React.Fragment key={id}>
                      <tr
                        onClick={() => {
                          contentCollapse("notApproved", id);
                        }}
                        className="dataRow"
                        key={id}
                      >
                        {feedItems.map((col, ids) => {
                          return (
                            <td className="tableData" key={ids}>
                              {items[col]}
                            </td>
                          );
                        })}
                        <td>
                          <input
                            type="checkbox"
                            style={{
                              opacity: "1",
                              position: "static",
                              height: "25px",
                            }}
                            checked={
                              checkList.includes(items.feedbackid)
                                ? true
                                : false
                            }
                            onChange={e => {
                              handleChecked(items.feedbackid, e.target.checked);
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        {!items.collapse ? (
                          <SubTable content={items.content} />
                        ) : null}
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
            {notApprovedApi.length === 0 ? (
              <h3 className="text-center ptb--90 ">
                {" "}
                No Un Approved data available
              </h3>
            ) : (
              <div className="feedButtonsWrap">
                <input
                  type="button"
                  className="feedButton"
                  value="Approve"
                  disabled={checkList.length == 0}
                  onClick={() => {
                    updateFeed("Approved");
                  }}
                />
                <input
                  type="button"
                  className="feedButton"
                  value="Delete"
                  disabled={checkList.length === 0}
                  onClick={() => {
                    updateFeed("Delete");
                  }}
                />
              </div>
            )}
          </React.Fragment>
        );
      // ----------------Approved--------------
      case "approved":
        return (
          <React.Fragment>
            <table className="mainTable" style={{width: "97%"}}>
              <thead className="tableHead">
                <tr>
                  <td className="tableHead">Name</td>
                  <td className="tableHead">Degree</td>
                  <td className="tableHead">College</td>
                  <td className="tableHead">Rating</td>
                  <td className="tableHead">Select</td>
                </tr>
              </thead>
              <tbody>
                {approvedApi.map((items, id) => {
                  return (
                    <React.Fragment>
                      <tr
                        className="approvedRow"
                        onClick={e => {
                          contentCollapse("approved", id);
                        }}
                        key={id}
                      >
                        {feedItems.map((col, ids) => {
                          return (
                            <td key={ids} className="tableData">
                              {items[col]}
                            </td>
                          );
                        })}
                        <td>
                          <input
                            type="checkbox"
                            style={{
                              opacity: "1",
                              position: "static",
                              height: "25px",
                            }}
                            checked={
                              checkList.includes(items.feedbackid)
                                ? true
                                : false
                            }
                            onChange={e => {
                              handleChecked(items.feedbackid, e.target.checked);
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        {!items.collapse ? (
                          <SubTable content={items.content} />
                        ) : null}
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
            {approvedApi.length == 0 ? (
              <h3 className="text-center ptb--90 ">
                {" "}
                No Approved data available
              </h3>
            ) : (
              <div className="unApprovefeedButtonsWrap">
                <input
                  type="button"
                  className="unApprovefeedButton"
                  value="Un Approve"
                  disabled={checkList.length === 0}
                  onClick={() => {
                    updateFeed("Approval Pending");
                  }}
                />
                <input
                  type="button"
                  className="unapproveDelButton"
                  disabled={checkList.length === 0}
                  value="Delete"
                  onClick={() => {
                    updateFeed("Delete");
                  }}
                />
              </div>
            )}
          </React.Fragment>
        );
      default:
        return;
    }
  };

  //  ----------Subtable---------------
  const SubTable = props => {
    return (
      <>
        <td className="text-center">
          <strong>Feedback:</strong>
        </td>
        <td className="subtable text-center" colSpan={4}>
          {props.content}
        </td>
      </>
    );
  };

  return (
    <React.Fragment>
      <div className="listPage">
        <div className="switchingTab">
          {listItems.map((item, ids) => {
            return (
              <div
                key={item.id}
                onClick={e => {
                  setSection(item.id);
                }}
                className={`tableData ${section === item.id ? "active" : ""}`}
              >
                {item.name}
              </div>
            );
          })}
        </div>

        {createTable()}
      </div>
    </React.Fragment>
  );
};

import ExamPage from './Pages/Exam/Questioncontainer';
import React from 'react';
import AdminPage from './Pages/adminPanel/adminPanel';
import Forgot from './Pages/Forgot';
import Terms from './Pages/terms';
import EditUser from './Pages/Edituser/editUser';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Entry from './Pages/Entry';
import Login from './Pages/login';
import Policy from './Elements/policy';
import TilePage from './Pages/Test/TestPage';
import { connect } from 'react-redux';
import Contactus from './Pages/contactus';
import RefundPolicy from './Elements/refundPolicy';
import AboutUs from './Elements/aboutUsDetail';
import { updateUserInfo } from './redux/loginData';
import  Reset from './Pages/resetpage';

const RoutePaths=({accountData,updateUserInfo})=>{

    return (
      <React.Fragment>
        <Router>
          <Routes>
            <Route path='/' exact element={<Entry accountData={accountData} updateUserInfo={updateUserInfo}/>} />
            <Route path='/login' element={<Login accountData={accountData} />} />
            <Route path='/test' element={accountData.accessToken ? <TilePage accountData={accountData} /> : <Login accountData={accountData} />} />
            <Route path='/test/:testId' element={accountData.accessToken ? <ExamPage accountData={accountData} /> : <Login accountData={accountData} />} />
            <Route path='adminPage/*' element ={accountData?.userTypes?.includes('testMetricsAdmin') ? <AdminPage accountData={accountData}/> : <Entry accountData={accountData}/>} />
            <Route path='/forgot-password' element={<Forgot />}/>
            <Route path='/terms-and-conditions' element={<Terms accountData={accountData}/>}/>
            <Route path='/privacy-policy' element={<Policy accountData={accountData}/>}/>
            <Route path='/my-profile' element={<EditUser  accountData={accountData}/>}/>
            <Route path='/contact-us' element={<Contactus accountData={accountData}/>}/>
            <Route path="/about-us" element={<AboutUs accountData={accountData}/>} />
            <Route path='/refund-policy' element={<RefundPolicy accountData={accountData}/>}/>
            <Route path='/reset-password/*' element={<Reset />} />
          </Routes>
        </Router>
      </React.Fragment>
    )
  };
  
  const mapStateToProps=(state)=>{
    return {accountData : state.accountData}
  }
  
  const mapDispatchToProps = (dispatch)=>{
    return {
      updateUserInfo : (value)=>dispatch(updateUserInfo(value))
    }
  }
  export default connect(mapStateToProps,mapDispatchToProps)(RoutePaths);
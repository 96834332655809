import { Header } from "../Elements/Header/header"
import { Footer } from "../Elements/Footer/footer"
import React,{useEffect} from "react"

export default function Terms({accountData}){
    
    useEffect(()=>{
        window.scrollTo({
         top: 0,
         behavior: 'smooth'
        })
      },[])
    
    return(
        <React.Fragment>
            <Header accountData={accountData}/>
            <center style={{marginTop:"30px", fontSize:"25px", fontWeight:"bold"}}><h3 className="m_title text-center">Terms & Conditions</h3></center>
            <div className="m_edu-privacy-policy-area m_edu-privacy-policy m_edu-section-gap m_bg-color-white" style={{padding:"60px"}}>
            <div className="m_container">
                <div className="m_row m_g-5">
                    <div className="m_content">
                       
                        <h4 className="m_title">Terms &amp; Conditions</h4>
                        <p>
Welcome! These Terms of Service (the âTermsâ) govern Your access to and use of the Test Your Metrics website and online community for software developers (âTest Your Metricsâ). These Terms also include the Test Your Metrics Privacy Policy. By accessing and using Test Your Metrics, You agree to comply with these Terms. You may not access and use Test Your Metrics if You do not agree to the version of the Terms posted at the time You access Test Your Metrics.<br/>The terms "We" and "Us" refer to Interviewstreet Incorporation, a Delaware corporation doing business as Test Your Metrics.
</p>

                        <h4 className="m_title">Acceptance of the Test Your Metrics Terms</h4>
                        <p>Your access to Test Your Metrics is subject to these Terms. We reserve the right to update and change these Terms from time to time without notice or acceptance by You, although if we decide to materially change all or part of the Terms, we will send You a notification through the Test Your Metrics site. Such modified Terms will become effective upon the earlier of (i) Your continued use of Test Your Metrics after We send a notification to You through the Test Your Metrics site; or (ii) thirty (30) days from publication of such modified Terms on this page.Your use of Test Your Metrics is also subject to the Test Your Metrics Privacy Policy, which explains how we treat Your personal information, and provides information about our data protection practices, Account Information Access. </p>
                      <p>  Test Your Metrics is not available to persons under the age of thirteen (13) or to anyone suspended or removed from Test Your Metrics. By using Test Your Metrics, You represent and warrant that You are at least thirteen (13) years of age and that You are of legal age to form a binding contract in Your place of residence, and are not a person barred from receiving services under the laws of the United States or other applicable jurisdiction.</p>
                      We reserve the right to refuse registration of, or cancel, any Test Your Metrics Account or access to Test Your Metrics by anyone in Our reasonable discretion, at any time.
Test Your Metrics Accounts will be accessed through a username and password that You will create (Your âCredentialsâ). Your Credentials are solely for Your use and must not be shared. Each Test Your Metrics online community (each âHackerâ), needs to have his or her own Test Your Metrics Account and Credentials. You agree that Your Credentials and information You provide upon registration of Your Test Your Metrics Account and at all other times will be true, accurate, current, and complete. You are responsible for maintaining the confidentiality of Credential, and for all activities that occur under Your Test Your Metrics Account. You agree to immediately notify Us of any unauthorized use of Your Test Your Metrics Account, Credentials, or any other breach of security. You agree that We will not be liable for any loss or damage arising from Your failure to provide Us with accurate information or to keep Your Credentials secure Content.

<p>Test Your Metrics provides certain features which enable Hackers to submit, post, and share data, text, software, graphics, messages or other material (âContentâ). Content that Hackers submit, post or share is subject to Terms. Content that You own and post on or through Test Your Metrics belongs to You and You may use it in any way, except as prohibited by applicable law or regulations. By using Test Your Metrics, You are granting Us permission to use the Content as described in these Terms.</p>

<p>By using Test Your Metrics You are granting Us a nonÂ­exclusive, worldwide, royaltyÂ­free, fully paidÂ­up, sublicenseable, irrevocable and transferable right and license to use, host, store, reproduce, create derivative works of, distribute, modify, display, and communicate Your Content. If You post Content on or through Test Your Metrics, You represent and warrant that You have the right to post the Content and to grant the above rights to Us. This license continues even if You terminate Your Test Your Metrics Account and/or stop using Test Your Metrics.</p>

<p>You understand that by posting Your Content that You are responsible for Your Content and any Content that You create, transmit or display while using Test Your Metrics, and for any consequences thereof. You further agree that under no circumstances will We be liable for the errors or omissions associated with Your Content. You represent and agree to all of the following and acknowledge We are explicitly relying on such representations and agreement with regard to Your Content and use of Content made available on Test Your Metrics:
The Content represents Your own original work and/or You have all necessary rights to disclose the Content. In doing so, You are not violating the rights of any third party and You know of no other individual or entity whose rights will be infringed or violated by the Content being viewed and used as described in these Terms.
You agree to use Test Your Metrics only for purposes that are legal, proper and in accordance with these Terms. Your disclosure of Your Content does not violate a confidential relationship with any third party or establish a confidential relationship Us.</p>

<p>You understand that You are responsible for Your Content that You upload, post, eÂ­mail, transmit, or otherwise make available through Test Your Metrics. Except as permitted in these Terms, You do not have the rights to use, reproduce, create derivative works of, distribute, publicly perform or publicly display any Content that does not belong to You, other than viewing of Content or the associated Challenges on or through the Test Your Metrics.</p>

<p>We cannot and need not control all Content posted by Hackers on or through Test Your Metrics, and We do not guarantee the accuracy, integrity or quality of such Content. You agree that under no circumstances will We be liable in any way for any Content, including any errors or omissions in any Content, or any loss or damage of any kind incurred as a result of Your use of any Content. You understand that You must evaluate and bear all risks associated with the use of any Content, including any reliance on the Content, integrity, and accuracy of such Content. We do not endorse and are not responsible for the accuracy, usefulness, safety, or of or relating to Content. YOU AGREE TO WAIVE, AND HEREBY DO WAIVE, ANY LEGAL OR EQUITABLE RIGHTS OR REMEDIES YOU HAVE OR MAY HAVE AGAINST US WITH RESPECT THERETO.</p>

<p>We reserve the right, in Our reasonable discretion, to refuse certain Content to be uploaded to and/or hosted by Test Your Metrics, or to edit or remove any Content at any time with or without notice. Without limiting the generality of the preceding sentence, We comply with the Digital Millennium Copyright Act, and will remove Content upon receipt of a compliant takedown notice (see the âCopyright Infringementâ section below).
You are solely responsible for Your Content and the consequences of posting or publishing it. In connection with Your Content, You affirm, represent, and warrant that: (i) You own, or have the necessary licenses, rights, consents, and permissions to use and authorize Us to use all patent, trademark, copyright, or other proprietary rights in and to Your Content to enable inclusion and use of Your Content in the manner contemplated by these Terms, and to grant the rights and license set forth above, and (ii) Your Content, Our use of Your Content pursuant to these Terms, and exercise of the license rights set forth above, do not and will not:
infringe, violate, or misappropriate any third party right, including any copyright, trademark, patent, trade secret, moral right, Privacy Right, right of publicity, or any other intellectual property or proprietary right;
slander, defame, libel, or invade the right of privacy, publicity, or other property rights of any other person;
violate any applicable law or regulation; or
require obtaining a license from or paying fees or royalties to any third party for the exercise of any rights granted in these Terms, including, by way of example and not limitation, the payment of any royalties to any copyright owners, including any royalties to any agency, collection society, or other entity that administers such rights on behalf of others.
If You provide Us with any comments, bug reports, feedback, or modifications proposed or suggested by You (âFeedbackâ), We shall have the right to use such Feedback at Our discretion, including, but not limited to the incorporation of such suggested changes into Test Your Metrics and/or our other products and services. You hereby grant to Us a perpetual, irrevocable, nonÂ­exclusive license under all rights necessary to incorporate and use Your Feedback for any purpose Code of Conduct.</p>

<p>We have the right, but not the obligation, to remove or block Content from Test Your Metrics that We determine in Our reasonable discretion to be in violation of these Terms, to be unlawful, offensive, threatening, libelous, defamatory, obscene or otherwise objectionable, that violates any party's intellectual property or that is detrimental to the quality or intended spirit of Test Your Metrics. We also have the right, but not the obligation, to limit or revoke the use privileges of the Test Your Metrics Account of any Hacker who posts such Content or engages in such behavior.
You alone are responsible for Your interaction with other Hackers via Test Your Metrics. If You have a dispute with one or more Hackers or if You disagree with the results of a Challenge, Your sole remedy is to cease using Test Your Metrics. You irrevocably and forever release Us (and Our officers, directors, agents, subsidiaries, contractors, joint ventures and employees) from claims, demands and damages (actual and consequential) of every kind and nature, known and unknown, arising out of or in any way connected with such disputes. IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE CALIFORNIA CIVIL CODE SECTION 1542, WHICH STATES: âA GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR.â</p>

             <p>We do not permit copyright infringing activities through Test Your Metrics and We reserve the right to terminate access to Test Your Metrics and remove all content submitted by any persons who are found to be repeat infringers. Any suspected fraudulent, abusive, or illegal activity may be grounds for termination of Your use of Test Your Metrics and may be referred to appropriate law enforcement authorities. These remedies are in addition to any other remedies We may have at law or in equity.
We will use common business sense regarding behavior or Content allowed on or through Test Your Metrics. Examples of unacceptable Content or behavior include:
Abuse, harassment, threats, flaming or intimidation of any person or organization.</p>     

   <p>Uploading or sending pornographic, threatening, embarrassing, hateful, racially or ethnically insulting, libelous, or otherwise inappropriate Content.
Uploading copyrighted material that is not Your own or that You do not have the legal right to distribute, display, and otherwise make available to others.
Making unsolicited offers, advertisements, proposals, or sending junk mail (aka spam)Impersonating another person or pretending to be affiliated with an organization with which You are not affiliated or misrepresenting the extent of Your affiliated or role with an affiliated organization or the source, identity or Content transmitted.</p> 

   <p>Engaging in or contributing to any illegal activity or activity that violates othersâ rights.
Use of derogatory, discriminatory or excessively graphic language.Transmitting worms, viruses or harmful software.
Disclosing the personal or proprietary information of another person or organization not otherwise permitted by applicable rules or law.
Sharing Test Your Metrics Accounts or Credentials with any third party or encouraging any other Hacker to do so.
International Use.</p>    

   <p>Test Your Metrics is hosted in the United States. If You use Test Your Metrics from outside the United States, You acknowledge that You are voluntarily transferring information, including personally identifiable information and Content to the United States and that You agree that Our collection, use, storage and sharing of Your information and Content is exclusively subject to the laws of the United States and not of the jurisdiction where You are located.
You agree that You will comply with all United States laws, rules and regulations applicable to the export of products, services, software and technical data regardless of the jurisdiction in which You are located.</p>    



     
                        <h4 className="m_title">Termination</h4>
                        <p>We, in Our reasonable discretion may terminate Your Test Your Metrics Account and/or Credentials and remove and discard any Content within Test Your Metrics if We believe that You have violated or acted inconsistently with the letter and spirit of these Terms.
You may terminate Your Test Your Metrics Account and Your right to use Test Your Metrics at any time and for any reason or no reason, by contacting Us user support at support@Test Your Metrics.com. However, if You have a separate agreement with Us which has conflicting terms regarding termination, those terms shall take precedence.
After cancellation or termination of Your Test Your Metrics Account for any reason, You will no longer have access to Your Test Your Metrics Account and all information and Content in Your Test Your Metrics Account or that You have stored on Test Your Metrics may be, but is not required to be deleted by Us. We will have no liability for information or Content that is deleted due to the cancellation or termination of Your Test Your Metrics Account for any reason.</p>
                        
                        <h4 className="m_title">Policies Regarding Copyright.</h4>
                        <p> If You believe that any content available through Test Your Metrics infringes Your copyright, You may submit a notification pursuant to the United Statesâ Digital Millennium Copyright Act (âDMCAâ) by providing our Copyright Agent with information by going to dmca.
Ownership; Proprietary Rights.
Test Your Metrics is owned and operated by Interviewstreet Incorporated. The visual interfaces, graphics, design, compilation, information, computer code, products, software (including any downloadable software), services, and all other elements of Test Your Metrics provided by Interviewstreet (âMaterialsâ) are protected by United States copyright, trade dress, patent, and trademark laws, international conventions, and all other relevant intellectual property and proprietary rights, and applicable laws. Except for any third party content or Content uploaded by You, all Materials are the copyrighted property of Interviewstreet or its subsidiaries or affiliated companies and/or third party licensors. All trademarks, service marks, and trade names are proprietary to Interviewstreet or its affiliates and/or third party licensors. Except as expressly authorized by Interviewstreet, You agree not to sell, license, distribute, copy, modify, publicly perform or display, transmit, publish, edit, adapt, create derivative works from, or otherwise make unauthorized use of the Materials.
Third Party Websites and Services.
Test Your Metrics may include links to third party web sites or services (âLinked Sitesâ) solely as a convenience. We do not endorse any such Linked Sites or the information, material, products, or services contained on other Linked Sites or accessible through other Linked Sites. Furthermore, We make no express or implied warranties with regard to the information, material, products, or services that are contained on or accessible through Linked Sites. Access and use of Linked Sites, including the information, material, products, and services on Linked Sites or available through Linked Sites, is solely at Your own risk.</p>

                        <h4 className="m_title">Disclaimers/ No Warranties. Limitation of Liability</h4>
                        <p>Except As May Be Set Forth In Any Separate Signed Agreement By Interviewstreet And You, Test Your Metrics Is Provided "as Is" With No Representations Or Warranties Of Any Kind, Express, Statutory Or Implied, As To The Operation Of Test Your Metrics, Or The Information, Content, Materials, Or Products Included On Test Your Metrics. To The Fullest Extent Permissible By Applicable Law, Interviewstreet And Its Affiliates, If Any, Disclaim All Warranties, Express, Statutory, Or Implied, Including, But Not Limited To, Implied Warranties Of Merchantability, Fitness For A Particular Purpose And NonÂ­infringement. Further, Interviewstreet And Its Affiliates Do Not Warrant The Accuracy Or Completeness Of The Information, Text, Graphics, Links Or Other Information Contained In Test Your Metrics. Some Jurisdictions Do Not Allow Limitations On Implied Warranties, So The Above Limitations May Not Apply To You. Except As May Be Set Forth In Any Separate Signed Agreement Between Interviewstreet And A You, Interviewstreet Does Not Warrant That Test Your Metrics Will Be Available At Any Time Or From Any Particular Location, Will Be Secure Or ErrorÂ­free, That Defects Will Be Corrected, Or That Test Your Metrics Is Free Of Viruses Or Other Potentially Harmful Components. No Advice Or Information, Whether Oral Or Written, Obtained From Interviewstreet Or Test Your Metrics Shall Create Any Warranty Not Expressly Stated In These Terms Of Service.
Except As May Be Set Forth In Any Separately Signed Agreement Between Interviewstreet And You, Neither Interviewstreet Nor Its Affiliates Will Be Liable, Under Any Theory Of Law, For Any Indirect, Incidental, Punitive, Or Consequential Damages, Including, But Not Limited To Loss Of Profits Or, Business Interruption, And/or Loss Of Information Or Data. Some Jurisdictions Do Not Allow The Exclusion Or Limitation Of Incidental Or Consequential Damages, So The Above Limitations And Exclusions May Not Apply To You. Notwithstanding Anything To The Contrary Contained Herein, Interviewstreetâs Maximum Aggregate Liability To You For Any Causes Whatsoever, And Regardless Of The Form Of Action, Will At All Times Be Limited To The Greater Of (i) The Amount Paid, If Any, By You To Interviewstreet For Test Your Metrics In The Six (6) Months Prior To The Action Giving Rise To Liability Or (ii) One Hundred Dollars ($100.00).</p>

                       
                        <p>The terms "We," "Us," and "Test Your Metrics" refer to Interviewstreet Incorporation, a Delaware corporation doing business as Test Your Metrics.</p>

1. Eligibility to Use Test Your Metrics Jobs To access or use Test Your Metrics Jobs, You must be 18 years of age or older and have the power and authority to enter into these Terms. Except as approved by Us, Test Your Metrics Jobs is for Your personal, non-commercial use. You may not use Test Your Metrics Jobs if we have terminated Your account or banned You.

2. Your Test Your Metrics Account
Test Your Metrics Account.In order to access and use Test Your Metrics Jobs, We require You to become a member of the Test Your Metrics online community (a "Hacker") by creating a Test Your Metrics account using Your name, email address, mobile telephone number and creating a password. Additional account registration requirements may also apply. You are entirely responsible for maintaining the confidentiality of Your password. You agree not to use the account or password of another Hacker at any time. You agree to notify Test Your Metrics immediately if You suspect any unauthorized use of Your account or access to Your password. You are solely responsible for any and all use of Your account. Passwords are subject to cancellation or suspension by Test Your Metrics at any time.
Social Sign In.If You access Test Your Metrics through a social networking site such as Facebook or Google+ ("Social Networking Site"), You agree that Test Your Metrics Jobs may access and store (if applicable) information (including personally identifiable information) accessible to Test Your Metrics Jobs through Your Social Networking Site subject to the privacy settings that You have set with the Social Networking Site so that the information is available on and through Test Your Metrics Jobs via Your Test Your Metrics account and corresponding Test Your Metrics profile page. You acknowledge and agree that Your relationships with Your Social Networking Sites are governed solely by Your agreements with those Social Networking Sites and Test Your Metrics is not liable for any information, including personally identifiable information that may be provided to Test Your Metrics by Your Social Networking Site in violation of the privacy settings that You have set with Your Social Networking Sites.
3. Your Use of Test Your Metrics Jobs
Browse Jobs.You can use Test Your Metrics Jobs to browse available software developer jobs posted by top companies in select regions (each a"Company").
Take Challenges.When You identify a job that interests You, You can request to solve a software coding challenge ("Challenge") from the Company that posted the job. Once You've submitted a request to take a Challenge, You will receive the Challenge via email. You may start the Challenge when convenient for You, but it's recommended that You take the Challenge within a few days to help ensure that Your Challenge submission is received by the Company before the job is closed. Once started, the Challenge must be solved within the time limit specified and You may only take the Challenge once.
Get Responses.When You complete a Challenge, Your code in response to the Challenge will be submitted to the Company together with the profile information from Your Test Your Metrics Account that You choose to share with the Company.
Rules of Conduct.You agree to use Test Your Metrics Jobs solely for lawful purposes in a manner consistent with these Terms and any and all applicable laws, regulations, or other binding obligations (including contractual obligations) You may have. You agree that You will not:
Consult and/or copy code from any source including a website, book, friend, or colleague in Your efforts to complete a Challenge;
Use any information obtained from Test Your Metrics in order to harass, abuse, or harm another person, or in order to contact, advertise to, solicit, or sell to any person or entity (including Companies) without their prior explicit consent;
Use Test Your Metrics and/or Test Your Metrics Jobs to post content that is offensive, or promotes racism, bigotry, hatred or physical harm of any kind against any group or individual, or is pornographic or sexually explicit in nature, bullies, harasses or advocates stalking, bullying, or harassment of another person, or is abusive, threatening, obscene, defamatory or libelous;
Introduce software or automated agents to Test Your Metrics and/or Test Your Metrics Jobs, or access Test Your Metrics and/or Test Your Metrics Jobs so as to produce multiple Test Your Metrics accounts, generate automated messages, or to strip or mine data from Test Your Metrics and/or Test Your Metrics Jobs;
Interfere with, disrupt, or create an undue burden on Test Your Metrics and/or Test Your Metrics Jobs, or the networks, systems, or services connected to Test Your Metrics and/or Test Your Metrics Jobs;
Interfere with, disrupt, modify, reverse engineer, or decompile any data or functionality of Test Your Metrics and/or Test Your Metrics Jobs.
4. Sharing Your Information on Test Your Metrics Jobs
Your Responsibility for Your Information. You are solely responsible for any and all content and information that is submitted through Your Test Your Metrics account to Test Your Metrics and/or Test Your Metrics Jobs, including the information and representations that You make in Your Test Your Metrics account and corresponding Test Your Metrics profile and the code You submit in response to a Challenge ("Your Information").
Representations Regarding Your Information. You represent and warrant that:
You own Your Information or otherwise have the right to provide, post, and/or share Your Information via Test Your Metrics and/or Test Your Metrics Jobs as described in these Terms;
Your Information does not violate the privacy rights, publicity rights, copyright rights, or other rights of any person or entity;
By providing, posting and/or sharing Your Information, You do not violate any binding confidentiality, non-disclosure, or contractual obligations You might have towards a third party, including Your current or former employer or any potential employer;
Your Information is accurate and correct, including any salary information or Your current, past or potential status as an employee; and
Any resume You upload and share is accurate and submitted on Your own behalf.
Submitting Your Information. By submitting Your Information to Test Your Metrics Jobs, You hereby grant to Test Your Metrics an unrestricted, irrevocable, perpetual, non-exclusive, fully-paid and royalty-free, license (with the right to sublicense through unlimited levels of sublicensees) to use, copy, perform, display, create derivative works of, and distribute Your Information in any and all media (now known or later developed) throughout the world. No compensation will be paid with respect to Your Information. You should only submit Your Information to Test Your Metrics Jobs that You are comfortable sharing with others under the terms and conditions of these Terms.
Feedback. At Your discretion, You may provide feedback to Test Your Metrics concerning the functionality and performance of Test Your Metrics Jobs from time to time, including, without limitation, identifying potential errors, improvements, modifications, bug fixes, or enhancements ("Feedback"). If You, through Your evaluation or otherwise, suggests any Feedback, You hereby grant Indeed a nonexclusive, worldwide, perpetual, irrevocable, royalty-free, sublicensable (through multiple layers of sublicensees) right and license to make, use, sell, sublicense, reproduce, distribute, perform, display, prepare derivative works from and otherwise exploit all such Feedback and materials for any purpose without restriction. You agree that Test Your Metrics may disclose that Feedback to any third party in any manner and You agree that Test Your Metrics has the ability to sublicense all Feedback in any form to any third party without restriction. By submitting any Feedback, You agree that Your disclosure is gratuitous, unsolicited and without restriction and will not place Test Your Metrics under any fiduciary or other obligation, and that We are free to use the Feedback without any additional compensation to You, and/or to disclose the Feedback on a non-confidential basis or otherwise to anyone. You further acknowledge that, by acceptance of Your Feedback, Test Your Metrics does not waive any rights to use similar or related ideas previously known to Test Your Metrics, or developed by its employees, or obtained from sources other than You.
5.Company Information on Test Your Metrics Jobs
Information provided by Companies is made available to You through Test Your Metrics Jobs, including company profiles, reviews, photos, job descriptions, and salary information ("Company Information"). Because Test Your Metrics does not control the Company Information, You acknowledge and agree that: (a) Test Your Metrics is not responsible for, and does not endorse any Company Information; (b) Test Your Metrics makes no guarantees about the accuracy, suitability, or quality of the Company Information; and (c) Test Your Metrics assumes no responsibility for inaccurate, misleading, or unlawful Company Information. Test Your Metrics and/or Test Your Metrics Jobs may contain links to Company websites. Your use of all such links to Company websites is at Your own risk. Test Your Metrics does not monitor or have any control over, and makes no claim or representation regarding any Company websites. To the extent such links are provided by Test Your Metrics, they are provided only as a convenience, and a link to a Company website does not imply our endorsement, adoption or sponsorship of, or affiliation with, such Company website. When You leave Test Your Metrics and/or Test Your Metrics Jobs, these Terms no longer govern.

6. Reservation of Rights
Test Your Metrics and its third party suppliers and partners hereby reserve all rights in and to Test Your Metrics Jobs and the underlying or related software for Test Your Metrics Jobs (collectively, the"Software"). Nothing in this Agreement will be deemed to grant any license therein other than to access and use Test Your Metrics Jobs as described in Section 3 above. You may not copy, decompile, disassemble, reverse engineer, reverse assemble, analyze or otherwise examine, prepare derivative works of, modify, or attempt to derive source code from any portion of Test Your Metrics Jobs or the Software. You may not frame or utilize framing techniques to enclose any marks of Test Your Metrics, the Companies, or other proprietary information (including images, text, page, layout or form), without Test Your Metricsâs prior written consent.

7. Disclaimer of Warranties
Test Your Metrics disclaims any and all responsibility and liability for the accuracy, completeness, legality, reliability, or operability or availability of information or content displayed or posted on Test Your Metrics Jobs by Test Your Metrics or third parties including, but not limited to the Companies. Test Your Metrics disclaims any responsibility for the deletion, failure to store, miss-delivery, or untimely delivery of any information or content. Test Your Metrics disclaims any responsibility for any harm resulting from downloading or accessing any information or content from Test Your Metrics Jobs or on websites accessed through Test Your Metrics Jobs. Under no circumstances shall Test Your Metrics be liable to You on account of Your use or misuse of or reliance on Test Your Metrics Jobs.

Test Your Metrics JOBS, AND ALL INCLUDED INFORMATION, CONTENT, MATERIALS AND SERVICES ARE PROVIDED âAS IS,â WITH NO WARRANTIES WHATSOEVER, EITHER EXPRESS OR IMPLIED. Test Your Metrics AND ITS LICENSORS AND PARTNERS (INCLUDING THE COMPANIES) EXPRESSLY DISCLAIM TO THE FULLEST EXTENT PERMITTED BY LAW ALL EXPRESS, IMPLIED, AND STATUTORY WARRANTIES, INCLUDING, WITHOUT LIMITATION, THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT OF PROPRIETARY RIGHTS. Test Your Metrics AND ITS LICENSORS AND PARTNERS (INCLUDING THE COMPANIES) DISCLAIM ANY WARRANTIES REGARDING THE SECURITY, RELIABILITY, TIMELINESS, AND PERFORMANCE OF Test Your Metrics JOBS. Test Your Metrics AND ITS LICENSORS AND PARTNERS (INCLUDING THE COMPANIES) DISCLAIM ANY WARRANTIES FOR ANY INFORMATION OR ADVICE OBTAINED THROUGH Test Your Metrics JOBS.

YOU UNDERSTAND AND AGREE THAT YOUR USE OF Test Your Metrics JOBS IS AT YOUR OWN DISCRETION AND RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGES TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE USE OF Test Your Metrics JOBS.

8. Limitation of Liability
UNDER NO CIRCUMSTANCES SHALL Test Your Metrics OR ITS LICENSORS OR PARTNERS (INCLUDING THE COMPANIES) BE LIABLE TO YOU OR Test Your Metrics JOBS USER ON ACCOUNT OF USE OR MISUSE OF OR RELIANCE ON Test Your Metrics JOBS, ARISING FROM ANY CLAIM RELATING TO THESE TERMS OR THE SUBJECT MATTER HEREOF. SUCH LIMITATION OF LIABILITY SHALL APPLY TO PREVENT RECOVERY OF DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, AND PUNITIVE DAMAGES WHETHER SUCH CLAIM IS BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), INDEMNITY, OR OTHERWISE, EVEN IF Test Your Metrics OR ITS LICENSORS OR PARTNERS (INCLUDING THE COMPANIES) HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND NOTWITHSTANDING THE FAILURE OF ESSENTIAL PURPOSE OF ANY REMEDY. SUCH LIMITATION OF LIABILITY SHALL APPLY WHETHER THE DAMAGES ARISE FROM USE OR MISUSE OF AND RELIANCE ON Test Your Metrics JOBS, FROM RELIANCE OR DAMAGE CAUSED BY INFORMATION POSTED ON Test Your Metrics JOBS, FROM INABILITY TO USE Test Your Metrics JOBS, OR FROM THE INTERRUPTION, SUSPENSION, OR TERMINATION OF Test Your Metrics JOBS (INCLUDING SUCH DAMAGES INCURRED BY THIRD PARTIES). THIS LIMITATION SHALL ALSO APPLY, WITHOUT LIMITATION, TO THE COSTS OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, LOST PROFITS, OR LOST DATA. SUCH LIMITATION SHALL FURTHER APPLY WITH RESPECT TO THE PERFORMANCE OR NON-PERFORMANCE OF Test Your Metrics JOBS OR ANY INFORMATION THAT APPEARS ON, OR IS LINKED OR RELATED IN ANY WAY TO Test Your Metrics JOBS. SUCH LIMITATION SHALL APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY AND TO THE FULLEST EXTENT PERMITTED BY LAW.

Without limiting the foregoing, under no circumstances shall Test Your Metrics or its licensors or partners (including the Companies) be held liable for any delay or failure in performance resulting directly or indirectly from acts of nature, forces, or causes beyond its reasonable control, including, without limitation, Internet failures, computer equipment failures, telecommunication equipment failures, other equipment failures, electrical power failures, strikes, labor disputes, riots, insurrections, civil disturbances, shortages of labor or materials, fires, floods, storms, explosions, acts of God, war, governmental actions, orders of domestic or foreign courts or tribunals, non-performance of third parties, or loss of or fluctuations in heat, light, or air conditioning.

9. Termination
These Terms remain in effect while You use Test Your Metrics Jobs and for as long as Your Test Your Metrics account remains open. You may delete Your account at any time. Test Your Metrics may suspend or terminate Your account and/or Your access Test Your Metrics Jobs without notice to You if we believe that You have violated these Terms. All provisions of these Terms shall survive termination or expiration of these Terms except those granting access to or use of Test Your Metrics Jobs. Test Your Metrics will have no liability whatsoever to You for any termination of Your account or related deletion of Your Information.

10. Changes to Terms
We may revise these Terms from time to time by posting an updated version on Test Your Metrics Jobs and You agree that the revised Terms will be effective fifteen (15) days after the change is posted. Your continued use of Test Your Metrics Jobs is subject to the most current effective version of these Terms.

11. Governing Law; Venue
These Terms shall be governed by and construed in accordance with the internal laws of the State of California without reference to the conflict of laws provisions thereof. Any disputes relating to the interpretation, execution or enforcement of these Terms or arising between You and Test Your Metrics shall be dealt with under the exclusive jurisdiction and venue of the courts of the State of California, sitting in Santa Clara County, or the United States Federal Court for the District of California, and the Parties irrevocably submit for all purposes to the jurisdiction of each such court.

12. Other
Except as specifically stated in another agreement we have with You, these Terms constitute the entire agreement between You and Test Your Metrics regarding the use of Test Your Metrics Jobs and these Terms supersede all prior proposals, negotiations, agreements, and understandings concerning the subject matter of these Terms. You represent and warrant that no person has made any promise, representation, or warranty, whether express or implied, not contained herein to induce You to agree to these Terms. Test Your Metricsâs failure to exercise or enforce any right or provision of these Terms shall not operate as a waiver of such right or provision. If any provision of these Terms is found to be unenforceable or invalid, then only that provision shall be modified to reflect the parties' intention or eliminated to the minimum extent necessary so that these Terms shall otherwise remain in full force and effect and enforceable. These Terms, and any rights or obligations hereunder, are not assignable, transferable or sublicensable by You except with Test Your Metrics's prior written consent, but may be assigned or transferred by Test Your Metrics without restriction. Any attempted assignment by You shall violate these Terms and be void. The Section titles in these Terms are for convenience only and have no legal or contractual effect; as used in these Terms, the word "including" means "including but not limited to." Please contact Test Your Metrics with any questions regarding these Terms at <a href='mailto:support@testyourmetrics.com'>support@testyourmetrics.com</a>.
                        
                         
                        
                    </div>
                </div>
            </div>
        </div>
        <Footer />
</React.Fragment>
    )
}
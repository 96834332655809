import React, { useState,useEffect } from 'react';
import fetchData from '../../serverCall/fetchData';

const EditUserType=({accountData})=>{

    const [userMail,setUserMail]=useState('')
  const [availablePlans, setAvailablePlans] = useState([]);
  const [alert, setAlert] = useState(false);
  const getPlans = async () => {
    var resultData = await fetchData({
      url: "adminPanel/list-plans",
      method: "get",
      headers: { token: accountData?.accessToken, username: accountData?.user },
    });
    if (resultData.msg === "success") {
      if (resultData.asset.length > 0) {
        resultData.asset.forEach((plan) => (plan.checkedStatus = false));
        setAvailablePlans(resultData.asset);
      } else {
        setAvailablePlans([]);
      }
    } else {
      setAvailablePlans([]);
      console.log(resultData);
    }
  };
  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert("");
      }, 3000);
    }
  }, [alert]);

  useEffect(() => {
    getPlans();
    //eslint-disable-next-line
    },[]);

  const handleUserChange = ({ target }) => {
    let copy = availablePlans.slice();
    copy = copy.map((row) => {
      if (row["planName"] === target.value)
        row["checkedStatus"] = !row["checkedStatus"];
      return row;
    });

    setAvailablePlans(copy);
  };

  const findUser = async () => {
    var user = await fetchData({
      url: "adminPanel/get-user-details",
      data: { userMail },
      method: "post",
      headers: { token: accountData?.accessToken, username: accountData?.user },
    });

    if (user.msg === "success") {
      let copy = availablePlans.slice();
      copy = copy.map((row) => {
        if (user.asset.userTypes.includes(row.planName))
          row.checkedStatus = true;
        else row.checkedStatus = false;
        return row;
      });
      setAvailablePlans(copy);
    } else if (user.msg === "Not found") {
      clearChecks();
      console.log(`User With given Mail Doesn't Exist`);
    } else {
      console.log(user);
    }
  };
  useEffect(() => {
    if (userMail == "") clearChecks();
  }, [userMail]);
  const EditTypes = async () => {
    let newUserTypes = availablePlans
      .filter((row) => {
        if (row.checkedStatus) return row;
      })
      .map((row) => row?.planName);
    var updated = await fetchData({
      url: "adminPanel/edit-user-plans",
      method: "post",
      data: { newUserTypes, userMail },
      headers: { token: accountData?.accessToken, username: accountData?.user },
    });

    if (updated.msg === "success") {
      setAlert(updated.desc);
      console.log("Edited data", updated);
      setUserMail("");
      clearChecks();
    } else {
      console.log("Failed", updated);
      setAlert(updated.desc);
    }
  };

  const clearChecks = () => {
    let planlist = availablePlans.slice();
    planlist = planlist.map((plan) => {
      plan.checkedStatus = false;
      return plan;
    });
    setAvailablePlans(planlist);
  };

  return (
    <>
      <style>
        {`
            .userEditForm .planEdit{
                opacity: 1 !important;
                position :relative !important;
                width: 5%;
            }
            .userEditForm{
                width: 300px;
                height: 500px;
                overflow: auto;
                margin-left: 300px;
            }
        `}
      </style>
      <form
        className="userEditForm"
        onSubmit={(e) => {
          e.preventDefault();
          if (userMail !== "") EditTypes();
        }}
      >
        <input
          type="mail"
          value={userMail}
          onChange={(e) => {
            setUserMail(e.target.value);
          }}
        />
        <input
          type="button"
          value="Search"
          onClick={(e) => {
            e.preventDefault();
            if (userMail !== "") findUser();
          }}
        />
        {availablePlans?.map((row, ind) => (
          <div key={ind}>
            {row.planName}
            <input
              key={ind}
              type="checkbox"
              className="planEdit"
              disabled={!userMail}
              name="planList"
              value={row.planName}
              checked={row.checkedStatus}
              onChange={(e) => handleUserChange(e)}
            />
          </div>
        ))}
        <span className="formError">{alert}</span>
        <input type="submit" value="Change Types" />
      </form>
    </>
  );
}

export default EditUserType;

import React,{useEffect} from 'react';
import image from './cnt-bg.jpg'
import { Header } from '../Elements/Header/header';
import { Footer } from '../Elements/Footer/footer';
import { useState } from 'react';
import { FeedbackContactus } from "./feedbackContactus";

export default function Contactus({ accountData }) {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    window.open(
      `mailto:support@testyourmetrics.com?subject=${subject}&body=${
        `\n Name: ${name}\n` + message + `\n Phone: ${phone}`
      }`
    );
    setName("");
    setMail("");
    setPhone("");
    setMessage("");
    setSubject("");
  };

  return (
    <>
      <Header accountData={accountData} />
      <div
        className="edu-breadcrumb-area breadcrumb-style-1 bg-image"
        style={{
          backgroundImage: `Url(${image})`,
          width: "100%",
          padding: "0px 33px 33px",
          paddingTop: "18.7208918249%",
          backgroundSize: "140% 90%",
        }}
      >
        <div className="container eduvibe-animated-shape">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb-inner text-start">
                <div className="page-title">
                  <h3 className="title" style={{ color: "white" }}></h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="edu-contact-us-area eduvibe-contact-us edu-section-gap bg-color-white">
        <div className="container eduvibe-animated-shape">
          <div className="row g-5">
            <div className="col-lg-12">
              <div className="section-title text-center">
                <span className="pre-title">Need Help?</span>
                <h3 className="title">Get In Touch With us</h3>
              </div>
            </div>
          </div>
          <div className="row g-5 mt--20">
            <div className="col-lg-6">
              <div className="contact-info pr--70 pr_lg--0 pr_md--0 pr_sm--0">
                <div className="row g-5">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="contact-address-card-1 website">
                      <div className="inner">
                        <div className="icon">
                          <i className="ri-global-line"></i>
                        </div>
                        <div className="content">
                          <h6 className="title">Our Website</h6>
                          <p>
                            <a
                              href="https://testyourmetrics.com/"
                              target="_blank"
                            >
                              https://testyourmetrics.com/
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="contact-address-card-1 phone">
                      <div className="inner">
                        <div className="icon">
                          <i className="icon-Headphone"></i>
                        </div>
                        <div className="content">
                          <h6 className="title">Call Us On</h6>
                          <p>
                            <a href="tel: +4875 (356) 2568">+91 95431 93292</a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="contact-address-card-1 email">
                      <div className="inner">
                        <div className="icon">
                          <i className="icon-mail-open-line"></i>
                        </div>
                        <div className="content">
                          <h6 className="title">Email Us</h6>
                          <p>support@testyourmetrics.com</p>
                          <p>&nbsp;</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="contact-address-card-1 location">
                      <div className="inner">
                        <div className="icon">
                          <i className="icon-map-pin-line"></i>
                        </div>
                        <div className="content">
                          <h6 className="title">Our Location</h6>
                          <p>3,3rd St,T.Nagar Ramanathapuram,CBE</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <form
                className="rnt-contact-form rwt-dynamic-form row"
                id="contact-form"
                onSubmit={handleSubmit}
              >
                <div className="col-lg-12">
                  <div className="form-group">
                    <input
                      name="contact-name"
                      id="contact-name"
                      type="text"
                      className="form-control form-control-lg"
                      placeholder="Name*"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control form-control-lg"
                      id="contact-email"
                      name="contact-email"
                      placeholder="Email*"
                      value={mail}
                      onChange={(e) => setMail(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      name="contact-phone"
                      id="contact-phone"
                      placeholder="Phone"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="subject"
                      name="subject"
                      placeholder="Subject"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      name="contact-message"
                      id="contact-message"
                      placeholder="Your Message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    ></textarea>
                  </div>
                </div>
                <div className="col-lg-12">
                  <button
                    className="rn-btn edu-btn w-100"
                    name="submit"
                    type="submit"
                  >
                    <span>Send Message</span>
                    <i className="icon-arrow-right-line-right"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
            <div className="shape-image scene shape-image-1">
              <span data-depth="-2.2">
                <img
                  src="assets/images/shapes/shape-04-01.png"
                  alt="Shape Thumb"
                />
              </span>
            </div>
            <div className="shape-image shape-image-2">
              <img
                src="assets/images/shapes/shape-02-08.png"
                alt="Shape Thumb"
              />
            </div>
          </div>
        </div>
      </div>
      <FeedbackContactus accountData={accountData} />
      <Footer />
    </>
  );
}
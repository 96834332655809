let storeValue = {};
export const clearStoreValue = () => (storeValue = {});
export const getStoreValue = () => storeValue;
export const setStoreValue = (key, value) => {
  storeValue[key] = value;
//  console.log("strval", storeValue);
};
export const getStoreValueByKey = (quesIdx) => {
  return storeValue[quesIdx];
};

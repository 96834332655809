import { useEffect } from "react";
import { menuItems,ProfileDropdownItems } from "./menuItems";
import logo from "./tym.png";
import $ from "jquery";
import { connect } from "react-redux";
import { removeToken } from "../../redux/loginData";
import {useNavigate} from 'react-router-dom'

export const Header = ({accountData,styles}) => {
  useEffect(() => {
    let header = document.getElementsByTagName("header")[0];
    if(styles?.position)
    header.style.position = styles.position;
    document.addEventListener("scroll", () => {
      if (window.scrollY > 200) {
        header.classList.add("sticky");
        if(styles?.position)
        header.style.position = "fixed"
      } else if (window.scrollY < 200) {
        header.classList.remove("sticky");
        if(styles?.position)
        header.style.position = styles.position;
      }
    });
  },[styles]);

  useEffect(() => {
    $(".hamberger-button")
      // .unbind("click")
      .on("click", function (e) {
        $(".popup-mobile-menu").addClass("active");
      });
    $(".close-menu")
      // .unbind("click")
      .on("click", function (e) {
        $(".popup-mobile-menu").removeClass("active");
        $(".popup-mobile-menu .mainmenu .has-droupdown > a")
          .siblings(".submenu, .rn-megamenu")
          .removeClass("active")
          .slideUp("400");
        $(".popup-mobile-menu .mainmenu .has-droupdown > a").removeClass(
          "open"
        );
      });
    $(".popup-mobile-menu .mainmenu .has-droupdown > a")
      .unbind("click")
      .on("click", function (e) {
        e.preventDefault();
        $(this)
          .siblings(".submenu, .rn-megamenu")
          .toggleClass("active")
          .slideToggle("400");
        $(this).toggleClass("open");
      });
    $(".popup-mobile-menu")
      .unbind("click")
      .on("click", function (e) {
        e.target === this &&
          $(".popup-mobile-menu").removeClass("active") &&
          $(".popup-mobile-menu .mainmenu .has-droupdown > a")
            .siblings(".submenu, .rn-megamenu")
            .removeClass("active")
            .slideUp("400") &&
          $(".popup-mobile-menu .mainmenu .has-droupdown > a").removeClass(
            "open"
          );
      });
  });

  return (
    <>
      <header className="edu-header  header-sticky header-transparent header-style-2 header-default ">
        <div className="row align-items-center" style={{maxHeight: "80px",alignContent:"center"}}>
          <div className="col-lg-4 col-xl-3 col-md-6 col-6">
            <Logo />
          </div>
          <div className="col-lg-6 d-none d-xl-block">
            <nav className="mainmenu-nav d-none d-lg-block" >
              <MainMenu accountData={accountData}/>
            </nav>
          </div>
          <div className="col-lg-8 col-xl-3 col-md-6 col-6">
            <div className="header-right d-flex justify-content-end">
              <nav className="mainmenu-nav">
              <HeaderMenuBar accountData={accountData}/>
              </nav>
              <MobileMenuBar />
            </div>
          </div>
        </div>
      </header>
      <PopupMobileMenu accountData={accountData}/>
      </>
  );
};

const Logo = () => {
  return (
    <>
      <div className="logo" style={{width:'fit-content'}}>
        <span href="#/">
          <img
            height={35}
            width={220}
            className="logo-light"
            src={logo}
            alt="Site Logo"
          />
        </span>
      </div>
    </>
  );
};

const MainMenu = ({accountData}) => {
  return (
    <>
      <ul className="mainmenu">
        {menuItems.map((menu,index) => {
          if(menu.title==='Admin')
          {
          if(accountData?.userTypes?.includes('testMetricsAdmin'))
          {return  <MenuItems items={menu} key={index}/>}
          else
          {return <div key={index}></div>}
        }else{
            return <MenuItems items={menu} key={index} />}
})}
      </ul>
    </>
  );
};

const MenuItems = connect( null,(dispatch)=>({logout: ()=>dispatch(removeToken())}) ) (({ items,logout,key }) => {
  const navigate= useNavigate();
  if (items.submenu) {
    return (
      <>
        <li className={`has-droupdown ${items.className}`}>
          <a href={"#" + items.href} style={{textTransform: 'uppercase',padding:"0 10px"}}>{items.title}</a>
          <ul className={`submenu ${items.subMenuClass}`}>
            {items.submenu.map((submenu,index) => (
              <MenuItems items={submenu}  key={index}/>
            ))}
          </ul>
        </li>
      </>
    );
  } else {
    return (
      <>
        <li className={items.className} onClick={items.title ==='Logout' ? ()=>{logout(); navigate('/');}  :null} key={key}>
          <a href={"#" + items.href} style={{textTransform: 'uppercase',padding: "0 10px"}}>{items.title}</a>
        </li>
      </>
    );
  }
});



const HeaderMenuBar = ({accountData}) => {

  return (
    <>
    <style>
      {`.mainmenu-nav .mainmenu li.has-droupdown > a.profile::after
      {
        visibility : hidden
      }
      `}
    </style>
      <div className="header-menu-bar">
        {accountData?.user ? (
          <>
            <div className="quote-icon quote-user mainmenu">
              <li className={`has-droupdown`}>
                <a href={"#"} className='profile' style={{ top: "9px" }}>
                  <span
                    className="white-box-icon"
                    style={{ backgroundColor: "#525fe1", color: "white" }}>
                    <i className="ri-user-line "></i>
                  </span>
                </a>
                <ul
                  className="submenu"
                  style={{ marginTop: "-25px", marginLeft: "-90px" }}>
                  {ProfileDropdownItems[0].submenu.map((itm, idx) => {
                    return <MenuItems items={itm} key={idx} />;
                  })}
                </ul>
              </li>
            </div>
          </>
        ) : (
          <>
            <div className="quote-icon quote-user d-none d-md-block ml--15 ml_sm--5">
              <a
                className="edu-btn btn-medium left-icon header-button"
                href="#/login">
                <i className="ri-user-line"></i>Login / Register
              </a>
            </div>
            {/* For Mobile*/}
            <div className="quote-icon quote-user d-block d-md-none ml--15 ml_sm--5">
              <a className="white-box-icon" href="#/login">
                <i className="ri-user-line"></i>
              </a>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const MobileMenuBar = () => {
  return (
    <>
      <div className="mobile-menu-bar ml--15 ml_sm--5 d-block d-xl-none">
        <div className="hamberger">
          <button className="white-box-icon hamberger-button header-menu">
            <i className="ri-menu-line"></i>
          </button>
        </div>
      </div>
    </>
  );
};

const PopupMobileMenu = ({accountData}) => {
  return (
    <>
      <div className="popup-mobile-menu">
        <div className="inner">
          <div className="header-top">
            <div className="logo">
              <a href="index.html">
                <img src={logo} alt="Site Logo" />
              </a>
            </div>
            <div className="close-menu">
              <button className="close-button">
                <i className="ri-close-line"></i>
              </button>
            </div>
          </div>
          <MainMenu accountData={accountData}/>
        </div>
      </div>
    </>
  );
};

import { useState, useEffect,useRef } from "react";
import { connect } from "react-redux";
import * as XLSX from "xlsx";
import fetchData from '../../serverCall/fetchData'
// import PopUp from "./popup";
// import TilePage from "./tilePage";

import Popup from "../../Elements/popup/popup";

function ExcelClass({accountData}) {
  const [excelFileError, setExcelFileError] = useState("No File Selected");
  const [excelData, setExcelData] = useState(null);
  const [columns, setColumns] = useState(null);
  const [duration, setDuration] = useState("");
  const [typeName, setTypeName] = useState("");
  const [popup,setPopup] = useState(false);
  const [excelToJSOn,setExcelToJSON]=useState({});
  const [planList,setPlanList] = useState([]);
  const [popupContent,setPopupContent]=useState('')
  const fileType = ["text/csv"];
  const fileRef = useRef();
  const handleFile = (e) => {
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile && fileType.includes(selectedFile.type)) {
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = (e) => {
          setExcelFileError(null);
          handleFileView(e.target.result);
        };
      } else {
        setExcelFileError("Please select only CSV file types");
      }
    } else {
      console.log("plz select your file");
    }
  };

  const getPlans = async (data) => {
    var resultData = await fetchData({
      url: "adminPanel/list-plans",
      method: "get",
      data,
      headers: { token: accountData?.accessToken, username: accountData?.user },
    });

    if (resultData.msg == "success") {
      if (resultData.asset.length > 0) 
      {setPlanList(resultData.asset);}
      else {
        setPlanList([]);
      }
    } else {
      setPlanList([]);
      console.log(resultData);
    }
  };

  useEffect(() => {
    const unloadCallback = (event) => {
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    getPlans();
    return () => window.removeEventListener("beforeunload", unloadCallback);
        //eslint-disable-next-line
  }, []);

  const refresh = () => {
    setDuration('');
    setTypeName("");
    fileRef.current.value='';
    setExcelData(null);

  };
  const handleFileView = (file) => {
    if (file !== null) {
      const workbook = XLSX.read(file, { type: "buffer" });
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      let data = XLSX.utils.sheet_to_json(worksheet,{defval: ''});
      const column = data[0];
      const filtered = Object.entries(column)
        .filter(([k, v]) => (v === true ? column[k] : ""))
        .map((FilVal) => FilVal[0]);
      const errorCheck = data.some((obj) =>
        filtered.some((col) => obj[col] === '')
      );
      const typeData = data.map((v, i) => v["section"]);
      typeData.shift();
      const length = {};
      typeData.forEach((element) => {
        length[element] = (length[element] || 0) + 1;
      });
      setPopupContent(formatContent(length));
      setColumns(Object.keys(column));
      // refresh();
      if (errorCheck) {
        setExcelFileError("Some Value Is Missing");
      } else {
        data = JSON.stringify(data);
        data = JSON.parse(data,(key,val)=>(
          typeof val !== 'object' && val !== null ? String(val) : val
        ))
        setExcelData(data);
        setPopup(true);
        const excelJson = {
          questionType: typeName ,
          testDuration:  parseInt(duration.trim()) ,
          questionData: data.filter((rows,i)=>(i!==0)),
          testStatus:'Active'
        };
        setExcelToJSON(excelJson);
      }
    } else {
      setExcelData(null);
    }
  };
  const submitData = async(e)=>{
    e.preventDefault();
    let response =  await fetchData({
      requestingPage: "adminPage",
      method: "post",
      url: "adminPanel/create-questionnaire",
      headers: {token: accountData.accessToken,username: accountData.user},
      data:excelToJSOn
  });

    if(response.msg==='success'){
      console.log('SuccessFully Inserted',response.desc,response.detail,response.asset);
      setPopupContent(response.desc+' '+response.detail);
      setPopup(true);
      refresh();
    } else{
      console.log('Failed to Upload',response.desc,response.detail,response.asset)
      setPopupContent('Failed to upload'+response.desc);
      setPopup(true);
    }
  }
  const changeType=(e)=>{
    setTypeName(e.target.value)
  }
  const popupCloser=()=>{

    return <button onClick={()=>setPopup(!popup)}>Ok</button>
  }
  const formatContent=(sectionsObj={})=>{
    return <div style={{fontSize: "15px", fontFamily: "sans-serif", alignItems: "strech"}}>
    <h4>Type : {typeName} Time : {duration} </h4>
    <u style={{textAlign: "center", font: "Arial", fontSize: "25px"}}>Questionnaire</u>
    {Object.entries(sectionsObj).map(([key,value])=><h4 style={{fontSize:"15px", margin: "15px"}}>{key} : {value}</h4>)}
    </div>
  }
  return (
    <>
      {popup && (
        <Popup
          Content={
          popupContent
          }
          ProceedButton={popupCloser()}
        />
      )}

      <div className="container adminPage" style={{ marginLeft: "100px",height:'85vh' }}>
        <div className="form">
          <form className="form-group" autoComplete="off" onSubmit={submitData}>
            <label>Question Type:</label>
            <select
            value={typeName}
              defaultValue=""
              onChange={changeType}
              required
              style={{ width: "200px" }}>
             <option value="" disabled>
                SELECT TYPE
              </option>
             { planList.length>0 && 
               planList.map(({planName})=> <option value={planName} name={planName}>{planName}</option>)
              }
            </select>
            <input
              style={{ height: "50px", width: "100px", fontSize: "16px" }}
              type="text"
              name="time"
              placeholder="Min"
              required
              value={duration}
              onChange={(e) => setDuration(e.target.value)}></input>
            <input
              type="file"
              accept="text/csv"
              className="form-control"
              onChange={handleFile}
              ref={fileRef}
              required
              style={{ width: !excelData ? "110px" : "30%" ,height: '40px',display:"inline"}}
            />

            <div>
              <input
                type="submit"
                value="UPLOAD"
                className="btn-btn-success"
                style={{
                  width: "100px",
                  justifyContent: "center",
                  borderRadius: "5px",
                  position: "absolute",
                  marginBottom: "50px",
                  right: "100px",
                }}
              />
            </div>
          </form>
        </div>

        <br></br>

        <div className="viewer">
          {excelData === null && <>{excelFileError}</>}
          {excelData !== null && (
            <div className="tableContainer">
              <table className="table">
                <thead>
                  <tr style={{ padding: "20px" }}>
                    {columns.map((Title, ind) => (
                      <th key={ind} className="columnData">
                        {Title}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {excelData.map((row, i) => {
                    if (i != 0)
                      return (
                        <tr key={i}>
                          {columns.map((key, j) => (
                            <td className="rowData" key={j}>
                              {row[key]}
                            </td>
                          ))}
                        </tr>
                      );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

const mapStateToProps=(state)=>{
  return {accountData: state.accountData}
}

export default connect(mapStateToProps,null)(ExcelClass);

import React from "react";
import tymLogo from './tymLogo.png';
import { useNavigate } from "react-router-dom";
import fetchData from "../../serverCall/fetchData";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}


export function Pricing({accountData,planList, testRef,updateUserInfo}) {
  const navigate=useNavigate();

  async function displayRazorpay(amountData) {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    const data= await fetchData({url:'payments/create-payment-order',
                        method: 'post',
                       data: amountData,
                       headers: {
                        "Content-Type": "application/json",
                        token: accountData.accessToken, user: accountData.user} })
if(data.msg==='success'){
    const options = {
      currency: data.asset.currency,
      amount: data.asset.amount.toString(),
      order_id: data.asset.id,
      name: "online exams",
      description: "Thank you for using this app",
      image: tymLogo,

      handler: async function ({razorpay_payment_id,razorpay_order_id,razorpay_signature}) {
        //  FOR DB
        const verify = await fetchData({
          url: 'payments/verification',
          method: 'post',
          headers: {token: accountData.accessToken,name: accountData.user},
          data: { order_id: razorpay_order_id, 
            payment_id: razorpay_payment_id, 
            rzp_signature: razorpay_signature,
          planName: amountData.planName,
          planExpiryInMonths: amountData.planExpiryInMonths }
        })

        if(verify.msg==='success')
        {
          updateUserInfo(verify.asset);
        navigate('/test');
      }
        else
        console.log("Try Later");
      },

      prefill: {
        name:"",
        email: "",
        phone_number: "",
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
    paymentObject.on('payment.failed', function (response){
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
  })
  } else{
    console.log(data)
  }
  }


  return (
    <>
    <style>
      {`
       .activeHover{
        transform : scale(1.06);
        transition: all 0.5s ease-in-out;
       }
       .pricing-btn > a{
        cursor: pointer;
       }
       .pricing-table .list-item {
        min-height: 300px;
    }
    .disabled-plan{
      pointer-events: none !important;
      background-color: gray !important;
    }
      `}
    </style>
      <div className="edu-pricing-area eduvibe-pricing-plan-page edu-section-gap bg-image bg-image--26"
      ref={testRef}
      >
        <div className="container eduvibe-animated-shape">
          <div className="row g-5">
            <div className="col-lg-12">
              <div
                className="section-title text-center"
                data-sal-delay="150"
                data-sal="slide-up"
                data-sal-duration="400"
              >
                <span className="pre-title">Pricing Plan</span>
                <h3 className="title">Choose Your Pricing Plan</h3>
              </div>
            </div>
          </div>
          <div className="row g-5 mt--20">
            {planList?.map((planItem,index) => {
              return (
                <React.Fragment key={index}>
                  <div className="col-xl-4 col-lg-6 col-md-6 col-12">
                    <div
                      className="pricing-table"
                      onMouseEnter={(e) =>
                        e.currentTarget.classList.toggle("activeHover")
                      }
                      onMouseLeave={(e) =>
                        e.currentTarget.classList.toggle("activeHover")
                      }>
                      <div className="pricing-header">
                        <h3 className="title">{planItem.planDisplayName || planItem.planName}</h3>
                        <div className="price-wrap">
                          <div className="yearly-pricing">
                            <span className="amount"> ₹{planItem.amount}</span>
                            <span className="duration">
                              /{planItem.timing} months
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="pricing-body">
                        <ul className="list-item">
                          {planItem?.content?.map((ContentRow, key) => (
                            <li key={key}>
                              <i className="icon-checkbox-circle-line"></i>{" "}
                              {ContentRow}
                            </li>
                          ))}
                        </ul>
                      </div>
                      {!accountData.accessToken ? (
                        <div className={`pricing-btn`}>
                          <a className={`edu-btn edu-dark ${planItem.status==='Disable' ? 'disabled-plan' : ''}`}
                          onClick={() => {navigate('/login');}}
                          >
                            Buy This Plan
                            <i className="icon-arrow-right-line-right"></i>{" "}
                          </a>
                        </div>
                      ) :(( accountData?.userTypes?.includes(
                          planItem.planName
                        ) || accountData?.userTypes.includes("testMetricsAdmin")) ? (
                        <div
                          className="pricing-btn"
                          onClick={() => navigate("/test")}>
                          <a
                            className="edu-btn edu-light"
                            style={{ backgroundColor: "green" }}>
                            Purchased
                          </a>
                        </div>
                      ) : (
                        <div
                          className="pricing-btn"
                          >
                          <a className={`edu-btn edu-dark ${planItem.status==='Disable' ? 'disabled-plan' : ''}`}
                          onClick={(e) =>{
                            e.preventDefault();
                            displayRazorpay({
                              amount: planItem.amount,
                              planName: planItem.planName,
                              planExpiryInMonths: planItem.timing
                            })
                            // updateUserInfo({userTypes: [planItem.planName]})
                            // navigate('/test')
                          }}
                          >
                            Buy This Plan
                            <i className="icon-arrow-right-line-right"></i>{" "}
                          </a>
                        </div>
                      )
                      )}
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

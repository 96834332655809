import React, { useEffect, useRef, useState } from "react";
import fetchData from "../../serverCall/fetchData";
import "./testPlan.css";

export function TestPlan({ accountData }) {
  const [text, setText] = useState({});
  const [alert, setAlert] = useState(false);
  const [textList, setTextList] = useState([]);
  const columnHeaders = [
    "Display Name",
    "Plan Name",
    "Plan Amount(in Rs)",
    "Validity(in months)",
    "Status",
    "Content",
  ];
  const columns = ["planDisplayName","planName", "amount", "timing", "status", "content"];
  const [planNameDisabled,setPlanNameDisabled]=useState(false);
  const sendPlans = async (data) => {
    var result = await fetchData({
      url: "adminPanel/create-plan",
      method: "post",
      data: data,
      headers: { token: accountData?.accessToken, username: accountData?.user },
    });

    if (result.msg == "success") {
      console.log("Plans Submitted");
      await getPlans();
      setAlert(result.desc);
      setPlanNameDisabled(false);
    } else {
      console.log(result);
      setAlert(result.desc);
    }
  };

  const getPlans = async (data) => {
    var resultData = await fetchData({
      url: "adminPanel/list-plans",
      method: "get",
      data,
      headers: {
        token: accountData?.accessToken,
        username: accountData?.user,
        userTypes: accountData.userTypes,
      },
    });
    if (resultData.msg == "success") {
      if (resultData.asset.length > 0) {
        resultData.asset = resultData.asset.map((plan)=>{
          plan['planDisplayName'] = plan['planDisplayName'] || plan['planName'];
          return plan;
        })
        setTextList(resultData.asset);
      } else {
        setTextList([]);
      }
    } else {
      setTextList([]);
      console.log(resultData);
    }
  };

  // get the all the plans
  useEffect(() => {
    getPlans();
  }, []);

  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert("");
      }, 3000);
    }
  }, [alert]);

  const handleChange = (e) => {
    setText({ ...text, [e.target.name]: e.target.value });
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setTextList([...textList, text]);
    setText({});
  };

  // const columns = Object.keys(text);

  const handleRowDelete = (rowIndex) => {
    setTextList(textList.filter((value, index) => index !== rowIndex));
  };

  const handleRowEdit = (rowIndex) => {
    let copyForList = textList.slice();
    setPlanNameDisabled(true)
    let editData = copyForList.filter((value, index) => index == rowIndex)[0];
    copyForList = copyForList.filter((value, index) => index !== rowIndex);
    setText(editData);
    setTextList(copyForList);
  };
  return (
    <>
      <div className="adminPayment">
        <div className="formElement">
          <form onSubmit={formSubmit}>
            <>
            <label htmlFor="text">PLAN Display NAME:</label>
              <input
                type="text"
                id="text"
                name="planDisplayName"
                value={text.planDisplayName || ""}
                onChange={handleChange}
                required
              />
              <label htmlFor="text">PLAN NAME:</label>
              <input
                type="text"
                id="text"
                name="planName"
                value={text.planName || ""}
                onChange={handleChange}
                required
                disabled={planNameDisabled}
              />
              <label htmlFor="planAmount">PLAN AMOUNT:</label>
              <input
                id="planamount"
                name="amount"
                value={text.amount || ""}
                type="text"
                onChange={handleChange}
                required
              />

              <label htmlFor="time"> Validity In Months:</label>
              <input
                id="time"
                name="timing"
                value={text.timing || ""}
                type="text"
                onChange={handleChange}
                required
              />
              <br />
              <label htmlFor="status">STATUS</label>

              <select
                id="dropdown"
                name="status"
                value={text.status || ""}
                required
                onChange={handleChange}
              >
                <option value="" disabled>
                  Select status
                </option>
                <option value="Active">Active</option>
                <option value="InActive">In Active</option>
                <option value="Disable">Disable</option>
              </select>

              <label htmlFor="fontstyle">DESCRIPTION:</label>
              <input
                id="style"
                name="content"
                value={text.content || ""}
                type="text"
                required
                onChange={handleChange}
              />
              <br />
              <br />
              <input type="submit" className="add" value="ADD" />
            </>{" "}
          </form>
        </div>
        {textList?.length > 0 ? (
          <table style={{ maxWidth: "1200px", marginTop: "25px" }}>
            <thead>
              <tr>
                {columnHeaders.map((col, ind) => (
                  <th key={ind}>{col}</th>
                ))}
                <th colSpan={2}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {textList.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {columns.map((col, ind) => (
                    <td key={ind}>{row[col]}</td>
                  ))}
                  <td >
                    <button onClick={(e) => handleRowEdit(rowIndex)}>Edit</button>
                  &nbsp;
                    <button onClick={(e) => handleRowDelete(rowIndex)}>Delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          ""
        )}
        <span className="formError" style={{ textAlign: "center" }}>
          {alert}
        </span>
        <input
          type="button"
          className="add"
          value="SEND & SAVE"
          onClick={() => sendPlans(textList)}
        />

        <div className="push"></div>
      </div>
    </>
  );
}

import "./audit.css";
import "../adminPanel/testPlan.css"
import fetchData from "../../serverCall/fetchData";
import { useState, useEffect } from "react";
// import { data } from "./auditMockData";
import {exportToCSV} from './jsonToExcel';
import { quizConst } from "../../utils/constants";

export default function UserAudit({accountData}) {
  const fileName=`User Audit ${new Date().toLocaleString()}`
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const [filter, setFilter] = useState({});
  const [userTypeList, setUserTypeList] = useState([]);
  const [alert, setAlert] = useState(false);
  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert("");
      }, 2000);
    }
  }, [alert]);

  const columnHeaders      =           ["UserName", "Email", "College", "Test Summary", "Registered Date", "Mobile"];
  const columns            =           ["UserName", "mail", "college","planDetails", "RegisteredDate", "mobile"];

  const handleChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  }
  

  const handleSubmit = (e) => {
    e.preventDefault();
    getUserData()
  }

  const getPlanList=async()=>{
    var resultData = await fetchData({
      url:'adminPanel/list-plans',
      method: 'get',
  });

  if(resultData.msg===quizConst.SUCCESS){
    if(resultData.asset.length >0)
    setUserTypeList(resultData.asset);
    else{
      setUserTypeList([]);
    }
  } else{
    setUserTypeList([]);
    console.log(resultData)
  }
  }
  async function getInformation(){
    await getUserData();
    await getPlanList();
  }
  useEffect(() => {
    const unloadCallback = (event) => {
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    getInformation();
    return () => window.removeEventListener("beforeunload", unloadCallback);
        //eslint-disable-next-line
  }, []);

  const getUserData = async (data) => {
    var resultData = await fetchData({
      url: 'adminPanel/user-audit',
      method: 'post',
      headers: {token: accountData?.accessToken,username: accountData.user},
      data:{filterParams:data ? data : filter},
    });
    if (resultData.msg === quizConst.SUCCESS) {
        setData(resultData.asset.table);
        setTotalCount(resultData.asset.totalCount);
        setAlert(resultData);
    } else {
      setData([]);
      setTotalCount([]);
      console.log(data);
    }
  };

 
  return (
    <div className="userAudit">
      <div className="userCount" >
        {totalCount?.map((count, i) => ( 
          <div className="userCount_item" key={i}>
            <h5>{count.planName}</h5>
            <center>{count.count}</center>
          </div>
        ))}
      </div>
      <div className="userAudit_filter">
        <div className="userAudit_filter_date">
          <label>Test Start Date:</label>
      <input type="date" name="startDate" value={filter.startDate || ""} onChange={handleChange} />
      </div>
      <div className="userAudit_filter_date">
        <label>Test End Date:</label>
         <input type="date" name="endDate" value={filter.endDate || ""} onChange={handleChange} />
      </div>
      <div className="userAudit_filter_username" style={{width: "250px"}}>
        <label >User Name:</label>
        <input type="text" name="username" value={filter.username || ""} style={{height: "45px"}} onChange={handleChange}/>
        </div>
      <div className="userAudit_filter_userType">
        <label>User Type:</label>
        <select style={{height: "45px", lineHeight: "20px"}} value={filter.usertype ||"default"} 
        name="usertype" onChange={handleChange}>
        <option value="default"> Select</option>
        { userTypeList.length>0 && 
               userTypeList.map(({planName},ind)=> <option key={ind} value={planName} name={planName}>{planName}</option>)
              }
        </select>
        </div>
        <div className="userAudit_filter_email">
        <label>Email:</label>
        <input type="email" name="email" value={filter.email || ""} style={{height: "45px"}} onChange={handleChange}/>
        </div>
      <div className="userAudit_filter_button">
        <button className="edu-btn btn-medium left-icon header-button" 
            onClick={handleSubmit}>Filter</button>
      </div>
      <div className="userAudit_filter_button">
        <button className="edu-btn btn-medium left-icon header-button" 
            onClick={()=>{setFilter({}); getUserData({});}}>Reset Filters</button>
      </div>
      <div className="userAudit_filter_button">
      <button  className="edu-btn btn-medium left-icon header-button"
                   onClick={()=>exportToCSV(data,fileName,filter)}>
          Export Data
        </button>
        </div>
      </div>
     
      <div className="userTable" >
      <table>
            <thead>
              <tr>
                <th>S. No.</th>
                {columnHeaders.map((col, ind) => (
                  <th key={ind}>{col}</th>
                ))}
                </tr>
            </thead>
            <tbody>
              {data?.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  <td>{rowIndex+1}</td>
                  {columns.map((col, ind) => {
                    if (col === "planDetails") {
                      return (
                        <td key={ind}>
                          {Object.entries(row[col]).map(([key, value],objInd) => (
                            <div key={objInd}>
                              <span>{key}:</span><span>{value}</span>
                            </div>
                          ))}
                        </td>
                      );
                    } 
                    if(col === "RegisteredDate"){
                      return (
                        <td key={ind}>
                          {row[col].split("T")[0]}
                        </td>
                      );
                    }else {
                      return <td key={ind}>{row[col]}</td>;
                    }
                  })
                 }
                </tr>
              ))}
            </tbody>
      </table>
      
      </div>
    </div>
  );
}
import "./assets/css/vendor/animation.css";
import "./assets/css/vendor/bootstrap.min.css";
import "./assets/css/vendor/eduvibe-font.css";
import "./assets/css/vendor/guidance-font.css";
import "./assets/css/vendor/jqueru-ui-min.css";
import "./assets/css/vendor/lightbox.css";
import "./assets/css/vendor/magnifypopup.css";
import "./assets/css/vendor/odometer.css";
import "./assets/css/vendor/remixicon.css";
import "./assets/css/vendor/slick.css";
import "./assets/css/style.css";
import sal from "sal.js";
import "../node_modules/sal.js/dist/sal.css";
import { useEffect, useRef, useState } from "react";
import { Pricing } from "./Pages/pricingPage/pricing";
import Banner from "./Elements/carousel/HeroSection";
import { Testimonial } from "./Elements/feedback/feedback";
import logoBadge from "./badge.png";
import "./App.css";
import fetchData from "./serverCall/fetchData";
import { PricingPlan } from "./Pages/pricingPage/pricingplan";

function Home({ accountData, updateUserInfo }) {
  const [planList, setPlanList] = useState([]);
  const getPlans = async () => {
    var result = await fetchData({
      url: "adminPanel/list-plans",
      method: "get",
    });
    if (result.msg === "success") {
      setPlanList(
        result.asset.map((plan) => {
          plan.content = plan.content.split(",");
          return plan;
        })
      );
    } else {
      setPlanList([]);
    }
  };

  const testRef = useRef(null);
  const scrollToElement = () =>
    testRef.current.scrollIntoView({ behavior: "smooth" });
  useEffect(() => {
    getPlans();
    sal({
      threshold: 0.5,
      once: true,
    });
  }, []);
  // This function will scroll the window to the top
  return (
    <>
      <Banner scrollToElement={scrollToElement} />
      <div id="pricing">
        <Pricing
          accountData={accountData}
          planList={planList}
          updateUserInfo={updateUserInfo}
        />
      </div>
      <div
        id="about-us"
        className="edu-about-area about-style-1 edu-section-gap bg-color-white"
      >
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6">
              <div className="about-image-gallery">
                <img
                  className="image-1"
                  src="assets/images/about/about_us-1.jpg"
                  alt="About Main Thumb"
                />
                <div className="image-2">
                  <img
                    src="assets/images/about/about_us-2.jpg"
                    alt="About Parallax Thumb"
                  />
                </div>
                <div className="badge-inner">
                  <img
                    className="image-3"
                    src={logoBadge}
                    alt="About Circle Thumb"
                  />
                </div>
                <div className="shape-image shape-image-1">
                  <img
                    src="assets/images/shapes/shape-04-01.png"
                    alt="Shape Thumb"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="inner">
                <div
                  className="section-title"
                  data-sal-delay="150"
                  data-sal="slide-up"
                  data-sal-duration="800"
                >
                  <span className="pre-title">About Us</span>
                  <h3 className="title" style={{ fontSize: "32px" }}>
                    ONE STOP SOLUTION FOR YOUR CAMPUS PLACEMENT PREPARATION
                  </h3>
                </div>
                <p
                  className="description"
                  data-sal-delay="150"
                  data-sal="slide-up"
                  data-sal-duration="800"
                >
                  Test Your Metrics is a Platform for ardent learners and Who
                  are in search of Quality Placement Preparation.
                </p>
                <div className="about-feature-list">
                  <div
                    className="our-feature"
                    data-sal-delay="150"
                    data-sal="slide-up"
                    data-sal-duration="800"
                  >
                    <div className="icon">
                      <i className="icon-Hand---Book"></i>
                    </div>
                    <div className="feature-content">
                      <h6 className="feature-title">Practice</h6>
                    </div>
                  </div>

                  <div
                    className="our-feature"
                    data-sal-delay="150"
                    data-sal="slide-up"
                    data-sal-duration="800"
                  >
                    <div className="icon">
                      <i className="icon-search-line"></i>
                    </div>
                    <div className="feature-content">
                      <h6 className="feature-title">Analyse</h6>
                    </div>
                  </div>

                  <div
                    className="our-feature"
                    data-sal-delay="150"
                    data-sal="slide-up"
                    data-sal-duration="800"
                  >
                    <div className="icon">
                      <i className="icon-bar-chart-2-line"></i>
                    </div>
                    <div className="feature-content">
                      <h6 className="feature-title">Improvise</h6>
                    </div>
                  </div>

                  <div
                    className="our-feature"
                    data-sal-delay="150"
                    data-sal="slide-up"
                    data-sal-duration="800"
                  >
                    <div className="icon">
                      <i className="icon-user-2-line_tie"></i>
                    </div>
                    <div className="feature-content">
                      <h6 className="feature-title">Land Your Dream Job</h6>
                    </div>
                  </div>
                </div>

                <div className="shape shape-6 about-parallax-2 d-xl-block d-none">
                  <img
                    src="assets/images/shapes/shape-07.png"
                    alt="Shape Thumb"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Testimonial />
      <div className="eduvibe-home-five-progress edu-service-area edu-section-gap bg-image">
        <div className="container eduvibe-animated-shape">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="section-title text-center"
                data-sal-delay="150"
                data-sal="slide-up"
                data-sal-duration="800"
              >
                <span className="pre-title">What We Offer</span>
                <h3 className="title">How Does Test Your Metrics Work?</h3>
              </div>
            </div>
          </div>
          <div className="row g-5 mt--20">
            <div
              className="service-card-single col-lg-4 col-md-6 col-12"
              data-sal-delay="150"
              data-sal="slide-up"
              data-sal-duration="800"
            >
              <div className="service-card service-card-7 shape-bg-1">
                <div className="inner">
                  <div className="icon">
                    <a href="#">
                      <i className="icon-book-line"></i>
                    </a>
                  </div>
                  <div className="content">
                    <h6 className="title">
                      <a href="#">Choose Any Plan</a>
                    </h6>
                    <p className="description">
                      {" "}
                      Take one Test Paper and Complete it
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="service-card-single col-lg-4 col-md-6 col-12"
              data-sal-delay="150"
              data-sal="slide-up"
              data-sal-duration="800"
            >
              <div className="service-card service-card-7 shape-bg-2">
                <div className="inner">
                  <div className="icon">
                    <a href="#">
                      <i className="icon-clock"></i>
                    </a>
                  </div>
                  <div className="content">
                    <h6 className="title">
                      <a href="#">Timely test</a>
                    </h6>
                    <p className="description">
                      Analyze your score and Time of Completion
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="service-card-single col-lg-4 col-md-6 col-12"
              data-sal-delay="150"
              data-sal="slide-up"
              data-sal-duration="800"
            >
              <div className="service-card service-card-7 shape-bg-3">
                <div className="inner">
                  <div className="icon">
                    <a href="#">
                      <i className="icon-student-read"></i>
                    </a>
                  </div>
                  <div className="content">
                    <h6 className="title">
                      <a href="#">Plan</a>
                    </h6>
                    <p className="description">
                      Plan on the Topics to be Concentrated upon
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="service-card-single col-lg-4 col-md-6 col-12"
              data-sal-delay="150"
              data-sal="slide-up"
              data-sal-duration="800"
            >
              <div className="service-card service-card-7 shape-bg-3">
                <div className="inner">
                  <div className="icon">
                    <a href="#">
                      <i className="icon-research"></i>
                    </a>
                  </div>
                  <div className="content">
                    <h6 className="title">
                      <a href="#">Prepare for Your Aptitude</a>
                    </h6>
                    <p className="description">
                      All these Aptitude Questions are tailor made for
                      industrial needs
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="service-card-single col-lg-4 col-md-6 col-12"
              data-sal-delay="150"
              data-sal="slide-up"
              data-sal-duration="800"
            >
              <div className="service-card service-card-7 shape-bg-1">
                <div className="inner">
                  <div className="icon">
                    <a href="#">
                      <i className="icon-pen-tool"></i>
                    </a>
                  </div>
                  <div className="content">
                    <h6 className="title">
                      <a href="#">Test! Test! Test!</a>
                    </h6>
                    <p className="description">Test Yourself Again</p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="service-card-single col-lg-4 col-md-6 col-12"
              data-sal-delay="150"
              data-sal="slide-up"
              data-sal-duration="800"
            >
              <div className="service-card service-card-7 shape-bg-2">
                <div className="inner">
                  <div className="icon">
                    <a href="#">
                      <i className="icon-checkbox-circle-fill"></i>
                    </a>
                  </div>
                  <div className="content">
                    <h6 className="title">
                      <a href="#">Repeat the Process</a>
                    </h6>
                    <p className="description">Practice makes you Perfect</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
